import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import i18n from "../i18n";

const headers = (headers = {}) => ({
  headers: {
    Accept: "application/json",
    "Accept-Language":
      localStorage.getItem("LibertyPaymentsLanguage") || i18n.language,
    ...headers,
  },
});

export const libertyPaymentsApi = createApi({
  reducerPath: "libertyPayments",
  tagTypes: ["Products", "Regions", "Activities"],
  baseQuery: fetchBaseQuery({
    // baseUrl: baseUrl,
    baseUrl: window.config.baseUrlForApi,
    ...headers(),
  }),
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: () => ({
        url: "/v1/Products",
        ...headers(),
      }),
      providesTags: ["Products"],
    }),
    getPosFaq: builder.query({
      query: (q = "POS") => ({
        url: `/v1/Faq/${q}`,
        ...headers(),
      }),
      providesTags: [`FAQ`],
    }),
    getRegions: builder.query({
      query: () => ({
        url: "/v1/references/regions",
        ...headers(),
      }),
      transformResponse: (result) =>
        result ? result.map((i) => ({ value: i.code, label: i.name })) : [],
      providesTags: ["Regions"],
    }),
    getActivities: builder.query({
      query: () => ({
        url: "/v1/references/activities",
        ...headers(),
      }),
      transformResponse: (result) =>
        result
          ? result.map((i) => ({
              label: i.name,
              value: i.code,
              productType: i.productType,
            }))
          : [],
      providesTags: ["Activities"],
    }),
    getCampaign: builder.query({
      query: (ProductType = "") => ({
        url: `/v1/active-campaign/${ProductType}`,
        ...headers(),
      }),
      providesTags: ["Campaign"],
    }),
    getPosTerminalTypes: builder.query({
      query: () => ({
        url: "/v1/references/posterminal-types",
        ...headers(),
      }),
      // transformResponse: (result) =>
      //   result.map((i) => ({
      //     label: i18n.t(`Application.Pos.${i}`),
      //     value: i,
      //   })),

      providesTags: ["PosTerminalTypes"],
    }),
    getPhonePrefixes: builder.query({
      query: () => ({
        url: "/v1/references/phone-meta-data",
        ...headers(),
      }),
      transformResponse: (result) =>
        result.map((i) => ({
          label: i.code,
          value: i.id,
          ...i,
        })),

      providesTags: ["PosTerminalTypes"],
    }),
    getPartnerLogos: builder.query({
      query: () => ({
        url: "/v1/references/partner-logos",
        ...headers(),
      }),
      providesTags: ["Partners"],
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetPosFaqQuery,
  useGetRegionsQuery,
  useGetActivitiesQuery,
  useGetCampaignQuery,
  useGetPosTerminalTypesQuery,
  useGetPhonePrefixesQuery,
  useGetPartnerLogosQuery,
} = libertyPaymentsApi;
