import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import backend from "i18next-http-backend";
import { baseUrl } from "./helpers/baseUrl";

export const setI18next = () => {
  i18n
    .use(backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      // resources,
      debug: true,
      returnEmptyString: false,
      parseMissingKeyHandler: (key) => key,
      whitelist: ["ka", "en-US"],
      lng: localStorage.getItem("LibertyPaymentsLanguage") || "ka",
      fallbackLng: "en-US",
      // loadPath: '/locales/{{lng}}/{{ns}}.json',
      load: "currentOnly",
      backend: {
        // for all available options read the backend's repository readme file
        loadPath: `${baseUrl}/v1/captions?Culture={{lng}}`,
      },

      keySeparator: false, // we do not use keys in form messages.welcome

      interpolation: {
        escapeValue: false, // react already safes from xss
      },

      react: {
        wait: true,
        bindI18n: "languageChanged loaded",
        bindStore: "added removed",
        nsMode: "default",
      },
    });
  window.i18n = i18n;
};

export default i18n;
