import React from "react";
import { Routes, Route } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import { ScrollTop } from "./components/simple/ScrollTop";
import SvgIcons from "./components/SvgIcons";
import loadable from "@loadable/component";

// scss
import "./scss/app.scss";
import "react-tooltip/dist/react-tooltip.css";
import { LbLoader } from "./components/simple/LbLoader";

// components
const PageNotFOundContainer = loadable(() => import("./pages/page404"), {
  fallback: <LbLoader inCenter={true} isLoading={true} />,
});
const MainContainer = loadable(() => import("./pages/index"), {
  fallback: <LbLoader inCenter={true} isLoading={true} />,
});
const BillingContainer = loadable(() => import("./pages/billing/index"), {
  fallback: <LbLoader inCenter={true} isLoading={true} />,
});
const CashBackContainer = loadable(() => import("./pages/cashback/index"), {
  fallback: <LbLoader inCenter={true} isLoading={true} />,
});
const ECommerceContainer = loadable(() => import("./pages/e-commerce/index"), {
  fallback: <LbLoader inCenter={true} isLoading={true} />,
});
const PosTerminalsContainer = loadable(() => import("./pages/pos/index"), {
  fallback: <LbLoader inCenter={true} isLoading={true} />,
});
const FaqContainer = loadable(() => import("./pages/faq/index"), {
  fallback: <LbLoader inCenter={true} isLoading={true} />,
});
const ApplicationContainer = loadable(
  () => import("./pages/application/index"),
  {
    fallback: <LbLoader inCenter={true} isLoading={true} />,
  }
);

function App() {
  const { ready } = useTranslation("Common");
  if (!ready) return <LbLoader inCenter={true} isLoading={true} />;
  return (
    <ScrollTop>
      <SvgIcons />
      <Routes>
        <Route path="*" element={<PageNotFOundContainer />} />
        <Route exact path="/" element={<MainContainer />} />
        <Route path="/billing" element={<BillingContainer />} />
        <Route path="/cashback" element={<CashBackContainer />} />
        <Route path="/e-commerce" element={<ECommerceContainer />} />
        <Route path="/pos" element={<PosTerminalsContainer />} />
        <Route path="/faq" element={<FaqContainer />} />

        <Route path="/application/create" element={<ApplicationContainer />} />
        <Route
          path="/application/create/:ProductType"
          element={<ApplicationContainer />}
        />
      </Routes>
    </ScrollTop>
  );
}

export default withTranslation()(App);
