import { useEffect, useState } from "react";

export const LbLoader = ({ isLoading, inCenter, className, delay }) => {
  /* hide loader if there is a delay. Set delay in milliseconds */
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (delay && isLoading)
      setTimeout(() => {
        setShow(true);
      }, delay);
    else if (!delay && isLoading) setShow(true);
    else {
      setShow(isLoading);
    }
  }, [delay, setShow, isLoading, show]);

  return show ? (
    <div
      className={`loader-out${inCenter ? " full-size " : ""} ${
        className ? className : ""
      } `}
    >
      <div className="loader" />
    </div>
  ) : null;
};
