import React from "react";
import ReactDOM from "react-dom/client";
import { ApiProvider } from "@reduxjs/toolkit/query/react";
import { unstable_HistoryRouter as Router } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n, { setI18next } from "./i18n";
import { history } from "./helpers/history";
import { setBaseUrls } from "./helpers/baseUrl";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

//apis
import { libertyPaymentsApi } from "./api/index";
import { LbLoader } from "./components/simple/LbLoader";


let xhrConfig;

function setConsole(server) {
  if (server === "production") {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
  }
}

const activateGoogleTagManager = (server, gtmId) => {
  if (server === "production") {
    // TagManager.initialize({
    //   gtmId: gtmId,
    // });
  }
};

const fetchConfig = () =>
  new Promise((resolve, reject) => {
    xhrConfig = new XMLHttpRequest();
    xhrConfig.open("GET", "/config.js", true);
    xhrConfig.setRequestHeader("Cache-Control", "no-cache");
    xhrConfig.onload = resolve;
    xhrConfig.onerror = reject; // () => reject(xhrConfig.statusText); //  console.error(xhrConfig.statusText);
    xhrConfig.send(null);
  });

function onConfigResult(config) {
  // set base properties

  setBaseUrls({
    baseUrl: config.baseUrlForApi,
    // staticBaseUrl: config.staticBaseUrl,
    authorizationUrl: config.authorizationUrl,
    lbUrl: config.baseLbApiUrl,
    server: config.server,
  });
  setConsole(config.server);
  activateGoogleTagManager(config.server, config.googleTag);
  //Oidc configuration
  // setUserManagerAuthorization(config.authorizationUrl, config.clientId);
}

function requestOnLoad() {
  if (xhrConfig.readyState === 4 && xhrConfig.status === 200) {
    // let serverConfig = JSON.parse(xhrConfig.responseText);
    onConfigResult(window.config || {});

    setI18next();
    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(
      <React.StrictMode>
        <React.Suspense fallback={ <LbLoader inCenter={true} isLoading={true} />}>
          
          <ApiProvider api={libertyPaymentsApi} >
            <I18nextProvider i18n={i18n}>
              <Router history={history}>
                <App />
              </Router>
            </I18nextProvider>
          </ApiProvider>
          
        </React.Suspense>
      </React.StrictMode>
    );
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    // serviceWorker.register();
    reportWebVitals();
  }
}

fetchConfig().then(requestOnLoad).catch();

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
