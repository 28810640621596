import React from "react";

const SvgIcons = () => {
  return (
    <svg
      style={{ width: 0, height: 0, position: "absolute", zIndex: -1 }}
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* logo */}
      <symbol id="logo-lb" viewBox="0 0 109 20" fill="currentColor">
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Categories" transform="translate(-24.000000, -135.000000)">
            <g id="Group-Copy" transform="translate(0.000000, 96.000000)">
              <g id="Group" transform="translate(24.000000, 39.000000)">
                <path
                  d="M88.1624264,0.0773250965 C86.8594404,0.0773250965 85.6933584,0.490862637 84.7701284,1.1945631 C83.8475524,0.490862637 82.6812524,0.0773250965 81.3782664,0.0773250965 C78.2388484,0.0773250965 75.8718044,2.45090211 75.8718044,5.59950207 L75.8718044,14.4780445 C75.8718044,17.6262015 78.2388484,20 81.3782664,20 C84.5176844,20 86.8851644,17.6262015 86.8851644,14.4780445 L86.8851644,4.98550847 C86.8851644,4.06717604 87.6854424,3.76040073 88.1624264,3.76040073 C88.6394104,3.76040073 89.4399064,4.04347571 89.4399064,4.98550847 L89.4399064,19.9623453 L93.6691064,19.9623453 L93.6691064,5.59950207 C93.6691064,2.45090211 91.3018444,0.0773250965 88.1624264,0.0773250965 L88.1624264,0.0773250965 Z M82.6557464,15.0918166 C82.6557464,15.9946442 81.8552504,16.3109439 81.3782664,16.3109439 C80.9012824,16.3109439 80.1010044,15.9011718 80.1010044,15.0918166 L80.1010044,4.98550847 C80.1010044,4.04347571 80.9012824,3.76040073 81.3782664,3.76040073 C81.8552504,3.76040073 82.6557464,4.06717604 82.6557464,4.98550847 L82.6557464,15.0918166 Z"
                  id="Fill-17"
                  fill="#000000"
                />
                <path
                  d="M11.842414,9.98266775 L11.842414,5.98329237 C11.842414,2.40941545 9.597014,0.0491283484 5.921098,0.0491283484 C2.245182,0.0491283484 0,2.40941545 0,5.98329237 L0,11.6308375 C0,17.2389558 4.69572,19.9698098 10.333636,19.9698098 L10.333636,16.2862911 C6.936106,16.2862911 4.228982,14.976128 4.228982,11.6308375 L4.228982,5.36885577 C4.228982,4.41951357 4.806028,3.64980658 5.921098,3.64980658 C7.036168,3.64980658 7.613432,4.41951357 7.613432,5.36885577 L7.613432,9.98266775 L11.842414,9.98266775 Z"
                  id="Fill-19"
                  fill="#000000"
                />
                <path
                  d="M67.3251144,6.84038049 C66.5773744,6.93939028 65.7884324,7.2299962 65.0273944,7.67077805 L65.0273944,7.18281704 C65.0273944,4.65197615 67.0284164,3.54271209 69.1155484,3.83885547 L69.1155484,0.0930957837 C64.2999284,-0.142357031 60.7624424,2.69149369 60.7624424,7.23885614 L60.7624424,14.4749878 L60.7624424,19.9639401 L65.0273944,19.9639401 L65.0289204,19.9639401 L65.0289204,14.173307 C65.0289204,11.5106746 65.7400364,10.6689806 66.7210364,10.4488112 C67.4862164,10.2767069 68.4129344,10.8718731 68.4129344,12.4637822 L68.4129344,19.9639401 L72.6423524,19.9639401 L72.6423524,11.5467788 C72.6423524,7.69248489 70.1715404,6.46361169 67.3251144,6.84038049"
                  id="Fill-21"
                  fill="#000000"
                />
                <path
                  d="M101.386568,5.31994892 C101.386568,4.37038522 101.963396,3.60067823 103.078684,3.60067823 C104.193754,3.60067823 104.770582,4.37038522 104.770582,5.31994892 L104.770582,19.9658671 L109,19.9658671 L109,5.93416403 C109,2.36006561 106.7546,0 103.078684,0 C99.40255,0 97.157368,2.36006561 97.157368,5.93416403 L97.157368,19.9658671 L101.386568,19.9658671 L101.386568,5.31994892 Z"
                  id="Fill-23"
                  fill="#000000"
                />
                <path
                  d="M19.1990638,5.36883362 C19.1990638,4.41949142 19.7758918,3.64956293 20.8911798,3.64956293 C22.0064678,3.64956293 22.5832958,4.41949142 22.5832958,5.36883362 L22.5832958,19.9660221 L26.8124958,19.9660221 L26.8124958,5.98304873 C26.8124958,2.4093933 24.5673138,0.0488847001 20.8911798,0.0488847001 C17.2150458,0.0488847001 14.9700818,2.4093933 14.9700818,5.98304873 L14.9700818,19.9660221 L19.1990638,19.9660221 L19.1990638,5.36883362 Z"
                  id="Fill-25"
                  fill="#000000"
                />
                <path
                  d="M51.4813758,0.0749550635 C47.8054598,0.0749550635 45.7032858,2.43502067 45.7032858,6.00911909 L45.7032858,7.88831164 L49.9324858,7.88831164 L49.9324858,5.39512548 C49.9324858,4.44511878 50.3663058,3.67563329 51.4813758,3.67563329 C52.6380838,3.67563329 53.0677618,4.44511878 53.0677618,5.39512548 L53.0677618,14.6333814 C53.0677618,15.5831666 52.4909338,16.3526521 51.3756458,16.3526521 C50.2603578,16.3526521 49.6835298,15.5831666 49.6835298,14.6333814 L49.6835298,11.5640778 L45.4545478,11.5640778 L45.4545478,14.0189448 C45.4545478,17.5932647 47.6995118,19.9531088 51.3756458,19.9531088 C55.0517798,19.9531088 57.2969618,17.5932647 57.2969618,14.0189448 L57.2969618,6.00911909 C57.2969618,2.43502067 55.1947878,0.0749550635 51.4813758,0.0749550635"
                  id="Fill-27"
                  fill="#000000"
                />
                <path
                  d="M39.8677746,7.17976036 C40.6717586,6.35290678 41.1491786,5.28927139 41.1491786,4.19484773 C41.1491786,1.84297756 39.8488086,0.415641775 36.6886806,0.415641775 L35.9075866,0.415641775 C35.7074626,0.415641775 35.5328446,0.41630627 35.3798086,0.39681441 C35.1121046,0.389283464 34.8814606,0.231133596 34.7667926,0.00321172701 L30.9762086,0.00321172701 C30.9762086,0.00321172701 31.2770486,1.17205886 32.0607586,2.03036522 C32.8446866,2.88867157 33.7873186,3.72837206 35.9075866,3.79194211 L36.0846026,3.79194211 C37.1484426,3.79194211 37.4438326,4.28411159 37.4580026,4.80108889 C37.4732626,5.37321929 37.0448926,5.97658097 36.0955026,5.97658097 L36.0955026,5.97813146 C36.0665086,5.97790996 36.0388226,5.97635947 36.0098286,5.97635947 C31.8665206,5.97635947 29.6989466,9.15663371 29.6989466,12.2979242 L29.6989466,13.3571296 L33.9279286,13.3571296 L33.9279286,11.6841521 C33.9279286,9.75711591 35.6619006,9.65943511 36.0098286,9.65943511 C36.3573206,9.65943511 38.0908566,9.75711591 38.0908566,11.6841521 L38.0908566,14.2550842 C38.0908566,16.1818989 36.3573206,16.2862247 36.0098286,16.2862247 L36.0093926,19.9628769 L36.0098286,19.9628769 C40.1527006,19.9628769 42.3202746,16.7826026 42.3202746,13.6413121 L42.3202746,12.2979242 C42.3202746,10.3509532 41.4870786,8.3902492 39.8677746,7.17976036"
                  id="Fill-29"
                  fill="#DB0012"
                />
              </g>
            </g>
          </g>
        </g>
      </symbol>
      <symbol id="logo-lben" viewBox="0 0 107 20" fill="currentColor">
        <svg
          viewBox="0 0 107 20"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Group 9</title>
          <g
            id="კომპონენტები"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="p2p-არ-ჩაიტვირთა-copy"
              transform="translate(-101.000000, -134.000000)"
            >
              <g id="Group-Copy-9" transform="translate(77.000000, 95.000000)">
                <g id="Group-9" transform="translate(24.000000, 39.000000)">
                  <polygon
                    id="Fill-1"
                    fill="#000000"
                    points="11.676268 15.5996148 11.676268 19.9999356 0 19.9999356 0 0 4.556488 0 4.556488 15.5996148"
                  ></polygon>
                  <polygon
                    id="Fill-2"
                    fill="#000000"
                    points="15.1210688 20 19.6775568 20 19.6775568 0.000279112808 15.1210688 0.000279112808"
                  ></polygon>
                  <g id="Group-13" transform="translate(23.124605, 0.000000)">
                    <path
                      d="M10.425652,9.62842571 C11.50785,8.68523912 12.162904,7.3712619 12.162904,5.71397593 C12.162904,2.25641235 9.34324,0.00010735108 5.869164,0.00010735108 L1.882772,0.00010735108 L0,0.00010735108 L0,4.28513306 L1.861158,4.28513306 L1.882772,4.28513306 L2.622784,4.28513306 L3.341396,4.28513306 L5.869164,4.28513306 C6.923114,4.28513306 7.606416,5.02821723 7.606416,6.05706998 C7.606416,7.08570803 6.894866,7.8287922 5.869164,7.8287922 L3.341396,7.8287922 L3.341396,11.8855895 L6.439046,11.8855895 C7.57774,11.8855895 8.318822,12.6855698 8.318822,13.8000887 C8.318822,14.9141782 7.57774,15.7141584 6.439046,15.7141584 L3.341396,15.7141584 L3.341396,19.9998282 L6.439046,19.9998282 C9.99915,19.9998282 12.874882,17.6857684 12.874882,14.1421092 C12.874882,12.1136032 11.906746,10.571827 10.425652,9.62842571"
                      id="Fill-3"
                      fill="#E03126"
                    ></path>
                    <polygon
                      id="Fill-5"
                      fill="#000000"
                      points="28.9111432 15.5996148 28.9111432 19.9999356 16.3803732 19.9999356 16.3803732 0 28.7690472 0 28.7690472 4.39946196 20.9372892 4.39946196 20.9372892 7.7140339 28.0568552 7.7140339 28.0568552 12.0570292 20.9372892 12.0570292 20.9372892 15.5996148"
                    ></polygon>
                    <polygon
                      id="Fill-7"
                      fill="#000000"
                      points="63.0362038 4.39967666 57.9102618 4.39967666 57.9102618 19.9999356 53.3539878 19.9999356 53.3539878 4.39967666 48.2276178 4.39967666 48.2276178 0 63.0362038 0"
                    ></polygon>
                    <polygon
                      id="Fill-9"
                      fill="#000000"
                      points="77.1831874 12.3136627 77.1831874 19.9997853 72.6266994 19.9997853 72.6266994 12.3136627 65.9342774 6.44106479e-05 71.1175714 6.44106479e-05 74.9049434 7.71409831 78.6925294 6.44106479e-05 83.8753954 6.44106479e-05"
                    ></polygon>
                    <path
                      d="M41.1271192,12.6566923 C43.3199772,11.5426028 44.8008572,9.31399439 44.8008572,6.85651347 C44.8008572,3.05757346 41.7539252,0.00021470216 37.9663392,0.00021470216 L32.0913972,0.00021470216 L32.0913972,4.28502571 L34.5491872,4.28502571 L37.9663392,4.28502571 C39.2193092,4.28502571 40.2443692,5.39911521 40.2443692,6.85651347 C40.2443692,8.31412644 39.2193092,9.42864535 37.9663392,9.42864535 L35.0448112,9.42864535 L35.0448112,13.4279025 L36.7129412,13.4279025 L40.4718512,19.9997209 L45.3707392,19.9997209 L41.1271192,12.6566923 Z"
                      id="Fill-11"
                      fill="#000000"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </symbol>

      {/* icons */}
      <symbol id="other" viewBox="0 0 64 64" fill="currentColor">
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Categories" transform="translate(-680.000000, -584.000000)">
            <g id="Group-Copy-7" transform="translate(396.000000, 552.000000)">
              <g
                id="Group-4-Copy-7"
                transform="translate(284.000000, 32.000000)"
              >
                <rect id="Rectangle" x="0" y="0" width="64" height="64" />
                <g
                  id="Group-6"
                  transform="translate(15.000000, 29.000000)"
                  strokeWidth="2"
                >
                  <circle
                    id="Oval"
                    stroke="#DB0012"
                    cx="31.5"
                    cy="3.5"
                    r="3.5"
                  />
                  <circle
                    id="Oval-Copy-6"
                    stroke="currentColor"
                    cx="17.5"
                    cy="3.5"
                    r="3.5"
                  />
                  <circle
                    id="Oval-Copy-7"
                    stroke="currentColor"
                    cx="3.5"
                    cy="3.5"
                    r="3.5"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </symbol>

      <symbol id="help" viewBox="0 0 20 20" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Organization-/-Status-/-help-/-20" fill="currentColor">
            <path
              d="M10,1.25 C5.16750844,1.25 1.25,5.16750844 1.25,10 C1.25,14.8324916 5.16750844,18.75 10,18.75 C14.8324916,18.75 18.75,14.8324916 18.75,10 C18.75,7.67935571 17.8281276,5.45375898 16.1871843,3.81281566 C14.546241,2.17187235 12.3206443,1.25 10,1.25 Z M10,17.5 C5.85786438,17.5 2.5,14.1421356 2.5,10 C2.5,5.85786438 5.85786438,2.5 10,2.5 C14.1421356,2.5 17.5,5.85786438 17.5,10 C17.5,11.9891237 16.7098237,13.896778 15.3033009,15.3033009 C13.896778,16.7098237 11.9891237,17.5 10,17.5 Z M10,15.625 C10.517767,15.625 10.9375,15.205267 10.9375,14.6875 C10.9375,14.169733 10.517767,13.75 10,13.75 C9.48223305,13.75 9.0625,14.169733 9.0625,14.6875 C9.0625,15.205267 9.48223305,15.625 10,15.625 Z M10.625,4.99999301 L9.6875,4.99999301 C8.94106966,4.99833571 8.22473189,5.29411735 7.69692462,5.82192462 C7.16911735,6.34973189 6.87333571,7.06606966 6.87499301,7.8125 L6.87499301,8.125 L8.125,8.125 L8.125,7.8125 C8.125,6.94955508 8.82455508,6.25 9.6875,6.25 L10.625,6.25 C11.4879449,6.25 12.1875,6.94955508 12.1875,7.8125 C12.1875,8.67544492 11.4879449,9.375 10.625,9.375 L9.37499301,9.375 L9.37499301,12.1875 L10.625,12.1875 L10.625,10.625 C12.1783009,10.625 13.4375,9.36580086 13.4375,7.8125 C13.4375,6.25919914 12.1783009,4.99999301 10.625,4.99999301 Z"
              id="Fill"
            />
          </g>
        </g>
      </symbol>
      <symbol id="earth-europe-africa" viewBox="0 0 20 20" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Planning-/-Travel-/-earth-europe-africa-/-20"
            fill="currentColor"
          >
            <path
              d="M10,1.25 C5.16750844,1.25 1.25,5.16750844 1.25,10 C1.25,14.8324916 5.16750844,18.75 10,18.75 C14.8324916,18.75 18.75,14.8324916 18.75,10 C18.7445579,5.16976441 14.8302356,1.25544206 10,1.25 Z M2.5,10 C2.50132953,7.8706287 3.41073959,5.84289435 5,4.4256875 L5,5.625 C5,6.31535594 5.55964406,6.875 6.25,6.875 L8.363625,6.875 C8.83724986,6.87505496 9.27022719,6.60742352 9.4819375,6.18375 L9.761375,5.625 L11.25,5.625 L11.25,8.125 L8.272125,8.125 C8.07825554,8.12503255 7.88705093,8.17015907 7.713625,8.2568125 L5.6909375,9.2681875 C5.26752637,9.47991461 5.0000516,9.91266483 5,10.3860625 L5,12.702 C5.00004751,13.2131083 5.31125201,13.6726999 5.7858125,13.8625 L8.19125,14.825 L8.6149375,17.3665 C5.07246982,16.6974297 2.50551939,13.6050939 2.5,10 Z M9.9035625,17.495125 L9.30875,13.925 L6.25,12.702 L6.25,10.386375 L8.272375,9.375 L12.5,9.375 L12.5,4.375 L8.988625,4.375 L8.363625,5.625 L6.25,5.625 L6.25,3.5146875 C8.17651711,2.39314938 10.5031162,2.19014837 12.5947567,2.96109321 C14.6863972,3.73203805 16.3247024,5.39643859 17.0625,7.5 L13.75,7.5 L13.75,10 L14.3265,12.3834375 L13.125,14.1858125 L13.125,16.8090625 C12.1457634,17.2632664 11.0794464,17.4990291 10,17.5 C9.9675,17.5 9.9360625,17.4954375 9.9035625,17.495125 Z M14.375,16.079125 L14.375,14.5641875 L15.3661875,13.077375 C15.5617153,12.78413 15.6245485,12.422039 15.53925,12.0800625 L15,9.923125 L15,8.75 L17.3870625,8.75 C17.8856851,11.570048 16.7125236,14.4246527 14.375,16.079125 L14.375,16.079125 Z"
              id="Fill"
            />
          </g>
        </g>
      </symbol>
      <symbol id="information" viewBox="0 0 16 16" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Organization-/-Status-/-information-/-16" fill="currentColor">
            <path
              d="M8.5,11 L8.5,7 L6.5,7 L6.5,8 L7.5,8 L7.5,11 L6,11 L6,12 L10,12 L10,11 L8.5,11 L8.5,11 Z M8,4 C7.58578644,4 7.25,4.33578644 7.25,4.75 C7.25,5.16421356 7.58578644,5.5 8,5.5 C8.41421356,5.5 8.75,5.16421356 8.75,4.75 C8.75,4.33578644 8.41421356,4 8,4 Z M8,15 C4.13400675,15 1,11.8659932 1,8 C1,4.13400675 4.13400675,1 8,1 C11.8659932,1 15,4.13400675 15,8 C15,9.85651543 14.2625021,11.6369928 12.9497475,12.9497475 C11.6369928,14.2625021 9.85651543,15 8,15 Z M8,2 C4.6862915,2 2,4.6862915 2,8 C2,11.3137085 4.6862915,14 8,14 C11.3137085,14 14,11.3137085 14,8 C14,4.6862915 11.3137085,2 8,2 Z"
              id="Fill"
            />
          </g>
        </g>
      </symbol>
      <symbol id="shopping-bag" viewBox="0 0 20 20" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Enterprise-/-Commerce-/-shopping--bag-/-20"
            fill="currentColor"
          >
            <path
              d="M11.875,2.5 C12.9105339,2.5 13.75,3.33946609 13.75,4.375 L13.75,4.375 L13.7499181,6.874 L15.7125,6.87495985 C16.3358042,6.86799441 16.8690382,7.32124332 16.9625,7.93754089 L17.625,16.266213 C17.6503279,16.4467809 17.5955405,16.6294057 17.475,16.766213 C17.3562483,16.9050491 17.1826949,16.9850032 17,16.9850032 L3,16.9850032 C2.81664009,16.9870395 2.64161858,16.9084881 2.521294,16.7701148 C2.40096942,16.6317415 2.34748249,16.4475082 2.375,16.266213 L3.05,7.93754089 C3.14346178,7.32124332 3.67669578,6.86799441 4.3,6.87495985 L6.24991814,6.874 L6.25,4.375 C6.25,3.38877723 7.01142049,2.58039375 7.97846985,2.50564118 L8.125,2.5 Z M15.7125,8.125 L13.7499181,8.124 L13.75,9.875 L12.5,9.875 L12.4999181,8.124 L7.49991814,8.124 L7.5,9.875 L6.25,9.875 L6.24991814,8.124 L4.2875,8.125 L3.73125,15.7349222 L16.26875,15.7349222 L15.7125,8.125 Z M11.875,3.75 L8.125,3.75 C7.77982203,3.75 7.5,4.02982203 7.5,4.375 L7.5,4.375 L7.49991814,6.874 L12.4999181,6.874 L12.5,4.375 C12.5,4.06120185 12.2687421,3.80141686 11.9673579,3.7567766 L11.875,3.75 Z"
              id="Combined-Shape"
            />
          </g>
        </g>
      </symbol>
      <symbol id="share" viewBox="0 0 20 20" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Tools-/-Technology-/-share-/-20" fill="currentColor">
            <path
              d="M16.25,17.75 L3.75,17.75 C3.05964406,17.75 2.5,17.1903559 2.5,16.5 L2.5,9 C2.5,8.30964406 3.05964406,7.75 3.75,7.75 L5.625,7.75 L5.625,9 L3.75,9 L3.75,16.5 L16.25,16.5 L16.25,9 L14.375,9 L14.375,7.75 L16.25,7.75 C16.9403559,7.75 17.5,8.30964406 17.5,9 L17.5,16.5 C17.5,17.1903559 16.9403559,17.75 16.25,17.75 Z M12.86875,5.88125 L10.625,3.64375 L10.625,10 L9.375,10 L9.375,3.64375 L7.13125,5.88125 L6.25,5 L10,1.25 L13.75,5 L12.86875,5.88125 L12.86875,5.88125 Z"
              id="Fill"
            />
          </g>
        </g>
      </symbol>
      <symbol id="logo-facebook" viewBox="0 0 20 20" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Brand-/-Social-/-logo-facebook-/-20" fill="currentColor">
            <path
              d="M16.66875,2.5 L3.33125,2.5 C2.87358024,2.50340289 2.50340289,2.87358024 2.5,3.33125 L2.5,16.66875 C2.50340289,17.1264198 2.87358024,17.4965971 3.33125,17.5 L10.5125,17.5 L10.5125,11.7 L8.5625,11.7 L8.5625,9.43125 L10.5125,9.43125 L10.5125,7.7625 C10.5125,5.825 11.69375,4.76875 13.43125,4.76875 C14.0125,4.76875 14.59375,4.76875 15.175,4.85625 L15.175,6.875 L13.98125,6.875 C13.0375,6.875 12.85625,7.325 12.85625,7.98125 L12.85625,9.425 L15.10625,9.425 L14.8125,11.69375 L12.85625,11.69375 L12.85625,17.5 L16.66875,17.5 C17.1264198,17.4965971 17.4965971,17.1264198 17.5,16.66875 L17.5,3.33125 C17.4965971,2.87358024 17.1264198,2.50340289 16.66875,2.5 Z"
              id="Fill"
            />
          </g>
        </g>
      </symbol>
      <symbol id="logo-linkedin" viewBox="0 0 20 20" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Brand-/-Social-/-logo-linkedin-/-20" fill="currentColor">
            <path
              d="M16.38125,2.49997949 L3.61875,2.49997949 C3.01390802,2.49636358 2.51697932,2.97663545 2.5,3.58125 L2.5,16.375 C2.51365958,16.9810727 3.01256383,17.4632539 3.61875,17.4563253 L16.38125,17.4563253 C16.9874362,17.4632539 17.4863404,16.9810727 17.5,16.375 L17.5,3.58125 C17.4830207,2.97663545 16.986092,2.49636358 16.38125,2.49997949 Z M6.94375,15.25625 L4.74375,15.25625 L4.74375,8.125 L6.94375,8.125 L6.94375,15.25625 Z M5.86875,7.13125 C5.16625969,7.13145496 4.59196834,6.57103538 4.575,5.86875 C4.56263507,5.52198323 4.69496564,5.18567958 4.94032261,4.94032261 C5.18567958,4.69496564 5.52198323,4.56263507 5.86875,4.575 C6.54675057,4.62220987 7.07263248,5.18598278 7.07263248,5.865625 C7.07263248,6.54526722 6.54675057,7.10904013 5.86875,7.15625 L5.86875,7.13125 Z M15.3,15.2125 L13.125,15.2125 L13.125,11.725 C13.125,10.89375 13.125,9.8125 11.9625,9.8125 C10.8,9.8125 10.625,10.725 10.625,11.64375 L10.625,15.175 L8.4,15.175 L8.4,8.125 L10.475,8.125 L10.475,9.0625 L10.51875,9.0625 C10.9523081,8.30955134 11.7695157,7.86117196 12.6375,7.9 C14.88125,7.9 15.3,9.4 15.3,11.30625 L15.3,15.2125 Z"
              id="Fill"
            />
          </g>
        </g>
      </symbol>
      <symbol id="bee" viewBox="0 0 14 12" fill="currentColor">
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Footer"
            transform="translate(-1567.000000, -104.000000)"
            fill="currentColor"
          >
            <g id="Language" transform="translate(1358.000000, 48.000000)">
              <g
                id="Brand-/-IBM-/-bee-/-16-Copy-2"
                transform="translate(206.000000, 52.000000)"
              >
                <path
                  d="M7.45,15.5875 C9.58244566,15.600912 11.6314377,14.7597309 13.1393343,13.2518343 C14.6472309,11.7439377 15.488412,9.69494566 15.475,7.5625 C15.475,7.4375 15.475,7.31875 15.475,7.19375 C16.0222455,6.79280546 16.4959125,6.30010714 16.875,5.7375 C16.3596984,5.96287216 15.8144437,6.11234353 15.25625,6.18125 C15.8480691,5.82861272 16.2921251,5.27354268 16.50625,4.61875 C15.9547008,4.94965055 15.3498849,5.18227206 14.71875,5.30625 C13.8449011,4.37514051 12.4550963,4.14599115 11.3285868,4.7472839 C10.2020772,5.34857666 9.61898716,6.63079069 9.90625,7.875 C7.63862619,7.76369522 5.52530137,6.69339845 4.09375,4.93125 C3.35310053,6.21673765 3.73566517,7.85630036 4.96875,8.68125 C4.53038274,8.66434312 4.10215794,8.54444018 3.71875,8.33125 L3.71875,8.3625 C3.71072317,9.696529 4.63503312,10.855171 5.9375,11.14375 C5.52977376,11.2534127 5.1026826,11.2704963 4.6875,11.19375 C5.06246218,12.3250812 6.10844862,13.0983105 7.3,13.125 C6.30575695,13.9253275 5.0700571,14.3658622 3.79375,14.375 C3.56997073,14.3685233 3.34671698,14.349745 3.125,14.31875 C4.41684697,15.1418113 5.91825276,15.5757436 7.45,15.56875"
                  id="Fill"
                />
              </g>
            </g>
          </g>
        </g>
      </symbol>
      <symbol id="search" viewBox="0 0 24 24" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Actions-/-Operations-/-search-/-24" fill="currentColor">
            <path
              d="M21.75,20.689425 L16.085925,15.02535 C18.9133336,11.6309352 18.5707781,6.61300919 15.3083015,3.63432866 C12.0458249,0.655648126 7.01751807,0.769911934 3.893715,3.893715 C0.769911934,7.01751807 0.655648126,12.0458249 3.63432866,15.3083015 C6.61300919,18.5707781 11.6309352,18.9133336 15.02535,16.085925 L20.689425,21.75 L21.75,20.689425 Z M3,9.75 C3,6.02207794 6.02207794,3 9.75,3 C13.4779221,3 16.5,6.02207794 16.5,9.75 C16.5,13.4779221 13.4779221,16.5 9.75,16.5 C6.02379137,16.4958667 3.00413335,13.4762086 3,9.75 Z"
              id="Fill"
            />
          </g>
        </g>
      </symbol>
      <symbol id="phone" viewBox="0 0 20 20" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Actions-/-Toggle-/-phone-/-20" fill="currentColor">
            <path
              d="M16.25,18.125 L16.14375,18.125 C3.8625,17.41875 2.11875,7.05625 1.875,3.89375 C1.83533288,3.39713929 1.99493398,2.90515892 2.31857351,2.52640685 C2.64221305,2.14765479 3.10327996,1.91327369 3.6,1.87499941 L7.04375,1.87499941 C7.55600481,1.87450411 8.01671587,2.1865987 8.20625,2.6625 L9.15625,5 C9.34322694,5.4644642 9.23501491,5.99568691 8.88125,6.35 L7.55,7.69375 C7.97595447,10.1143428 9.86803804,12.0114186 12.2875,12.44375 L13.64375,11.1 C14.0034217,10.7501072 14.5374623,10.6492055 15,10.84375 L17.35625,11.7875 C17.8247902,11.9829394 18.1285907,12.442345 18.1250315,12.95 L18.1250315,16.25 C18.1250315,17.2855339 17.2855339,18.125 16.25,18.125 Z M3.75,3.125 C3.40482203,3.125 3.125,3.40482203 3.125,3.75 L3.125,3.8 C3.4125,7.5 5.25625,16.25 16.2125,16.875 C16.3783337,16.8852496 16.5414236,16.8290462 16.6657351,16.7188076 C16.7900466,16.6085691 16.8653474,16.4533696 16.875,16.2875 L16.875,12.95 L14.51875,12.00625 L12.725,13.7875 L12.425,13.75 C6.9875,13.06875 6.25,7.63125 6.25,7.575 L6.2125,7.275 L7.9875,5.48125 L7.05,3.125 L3.75,3.125 Z"
              id="Fill"
            />
          </g>
        </g>
      </symbol>
      <symbol id="chevron-left" viewBox="0 0 20 20" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Actions-/-Navigation-/-chevron--left-/-20" fill="currentColor">
            <polygon
              id="Fill"
              points="5.25 10 11.5 3.75 12.375 4.625 7 10 12.375 15.375 11.5 16.25"
            />
          </g>
        </g>
      </symbol>
      <symbol id="chevron-right" viewBox="0 0 20 20" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Actions-/-Navigation-/-chevron--right-/-20"
            fill="currentColor"
          >
            <polygon
              id="Fill"
              points="14.75 10 8.5 16.25 7.625 15.375 13 10 7.625 4.625 8.5 3.75"
            />
          </g>
        </g>
      </symbol>
      <symbol id="chevron-down" viewBox="0 0 20 20" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Actions-/-Navigation-/-chevron--down-/-20" fill="currentColor">
            <polygon
              id="Fill"
              points="10 13.75 3.75 7.5 4.625 6.625 10 12 15.375 6.625 16.25 7.5"
            />
          </g>
        </g>
      </symbol>
      <symbol id="checkmark-filled" viewBox="0 0 20 20" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Organization-/-Status-/-checkmark--filled-/-20"
            fill="currentColor"
          >
            <path
              d="M10,1.25 C5.16750844,1.25 1.25,5.16750844 1.25,10 C1.25,14.8324916 5.16750844,18.75 10,18.75 C14.8324916,18.75 18.75,14.8324916 18.75,10 C18.75,7.67935571 17.8281276,5.45375898 16.1871843,3.81281566 C14.546241,2.17187235 12.3206443,1.25 10,1.25 Z M8.75,13.49425 L5.625,10.36925 L6.619125,9.375 L8.75,11.50575 L13.38125,6.875 L14.3785625,7.8661875 L8.75,13.49425 Z"
              id="Fill"
            />
            <polygon
              id="Inner-Fill"
              opacity="0"
              points="8.75 13.494375 5.625 10.369375 6.619375 9.375 8.75 11.505625 13.38125 6.875 14.378125 7.865625"
            />
          </g>
        </g>
      </symbol>
      <symbol id="symbol" viewBox="0 0 14 15" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Enterprise-/-Commerce-/-currency--gel-/-20"
            transform="translate(-3.000000, -2.000000)"
            fill="currentColor"
          >
            <g id="₾" transform="translate(3.500000, 2.600000)">
              <path d="M3.024,13.23 L0.342,13.23 L0.342,14.4 L12.366,14.4 L12.366,13.176 L6.516,13.176 C3.564,13.176 1.26,10.8 1.26,7.992 C1.26,5.76 2.646,3.888 4.626,3.15 L4.626,8.1 L5.706,8.1 L5.706,2.862 C5.976,2.826 6.246,2.808 6.516,2.808 C6.786,2.808 7.056,2.826 7.326,2.862 L7.326,8.1 L8.406,8.1 L8.406,3.15 C10.404,3.906 11.772,5.832 11.772,8.1 L13.032,8.1 C13.032,5.148 11.124,2.664 8.406,1.854 L8.406,0 L7.326,0 L7.326,1.638 C7.056,1.602 6.786,1.584 6.516,1.584 C6.246,1.584 5.976,1.602 5.706,1.638 L5.706,0 L4.626,0 L4.626,1.854 C1.98,2.628 0,5.094 0,7.992 C0,10.224 1.098,12.186 3.024,13.23 Z" />
            </g>
          </g>
        </g>
      </symbol>
      <symbol id="icn-close" viewBox="0 0 12 12" fill="currentColor">
        <path
          d="M11.611 9.855C10.332 8.555 9.054 7.279 7.775 6l3.855-3.855A1.256 1.256 0 0 0 9.855.37L6 4.225C4.721 2.946 3.443 1.668 2.145.389A1.256 1.256 0 0 0 .37 2.164C1.67 3.443 2.946 4.721 4.225 6 2.946 7.279 1.668 8.557.389 9.855a1.256 1.256 0 0 0 1.775 1.775C3.443 10.33 4.721 9.054 6 7.775l3.855 3.855c1.126 1.126 2.901-.649 1.756-1.775z"
          fill="currentColor"
        />
      </symbol>

      <symbol id="close" viewBox="0 0 20 20" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Actions-/-Navigation-/-close-/-20" fill="currentColor">
            <polygon
              id="Fill"
              points="15 5.875 14.125 5 10 9.125 5.875 5 5 5.875 9.125 10 5 14.125 5.875 15 10 10.875 14.125 15 15 14.125 10.875 10"
            ></polygon>
          </g>
        </g>
      </symbol>
      <symbol id="info" viewBox="0 0 16 16" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Organization-/-Status-/-information-/-16" fill="currentColor">
            <path
              d="M8.5,11 L8.5,7 L6.5,7 L6.5,8 L7.5,8 L7.5,11 L6,11 L6,12 L10,12 L10,11 L8.5,11 L8.5,11 Z M8,4 C7.58578644,4 7.25,4.33578644 7.25,4.75 C7.25,5.16421356 7.58578644,5.5 8,5.5 C8.41421356,5.5 8.75,5.16421356 8.75,4.75 C8.75,4.33578644 8.41421356,4 8,4 Z M8,15 C4.13400675,15 1,11.8659932 1,8 C1,4.13400675 4.13400675,1 8,1 C11.8659932,1 15,4.13400675 15,8 C15,9.85651543 14.2625021,11.6369928 12.9497475,12.9497475 C11.6369928,14.2625021 9.85651543,15 8,15 Z M8,2 C4.6862915,2 2,4.6862915 2,8 C2,11.3137085 4.6862915,14 8,14 C11.3137085,14 14,11.3137085 14,8 C14,4.6862915 11.3137085,2 8,2 Z"
              id="Fill"
            />
          </g>
        </g>
      </symbol>

      <symbol id="shopping-bag-plus" viewBox="0 0 15 15" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Enterprise-/-Commerce-/-shopping--bag--add-/-20"
            transform="translate(-2.000000, -2.000000)"
            fill="#000000"
          >
            <path
              d="M17,12.75 L14.5,12.75 L14.5,10.25 L13.25,10.25 L13.25,12.75 L10.75,12.75 L10.75,14 L13.25,14 L13.25,16.5 L14.5,16.5 L14.5,14 L17,14 L17,12.75 L17,12.75 Z M11.875,2.5 C12.8612228,2.5 13.6696062,3.26142049 13.7443588,4.22846985 L13.75,4.375 L13.7499181,6.874 L15.7125,6.87495985 C16.3358042,6.86799441 16.8690382,7.32124332 16.9625,7.93754089 L16.9749181,8.1 L9.5,8.1 L9.49991814,8.124 L7.49991814,8.124 L7.5,9.875 L6.25,9.875 L6.24991814,8.124 L4.2875,8.125 L3.73125,15.7349222 L9.49991814,15.734 L9.49991814,16.985 L3,16.9850032 C2.81664009,16.9870395 2.64161858,16.9084881 2.521294,16.7701148 C2.40096942,16.6317415 2.34748249,16.4475082 2.375,16.266213 L3.05,7.93754089 C3.14346178,7.32124332 3.67669578,6.86799441 4.3,6.87495985 L6.24991814,6.874 L6.25,4.375 C6.25,3.38877723 7.01142049,2.58039375 7.97846985,2.50564118 L8.125,2.5 L11.875,2.5 Z M11.875,3.75 L8.125,3.75 C7.81120185,3.75 7.55141686,3.98125788 7.5067766,4.28264208 L7.5,4.375 L7.49991814,6.874 L12.4999181,6.874 L12.5,4.375 C12.5,4.06120185 12.2687421,3.80141686 11.9673579,3.7567766 L11.875,3.75 Z"
              id="Fill"
            />
          </g>
        </g>
      </symbol>
      <symbol id="warning-filled" viewBox="0 0 20 20" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Organization-/-Status-/-warning--filled-/-20"
            fill="currentColor"
          >
            <path
              d="M10,1.25 C5.1875,1.25 1.25,5.1875 1.25,10 C1.25,14.8125 5.1875,18.75 10,18.75 C14.8125,18.75 18.75,14.8125 18.75,10 C18.75,5.1875 14.8125,1.25 10,1.25 Z M9.3125,5 L10.6875,5 L10.6875,11.875 L9.3125,11.875 L9.3125,5 L9.3125,5 Z M10,15.625 C9.5,15.625 9.0625,15.1875 9.0625,14.6875 C9.0625,14.1875 9.5,13.75 10,13.75 C10.5,13.75 10.9375,14.1875 10.9375,14.6875 C10.9375,15.1875 10.5,15.625 10,15.625 Z"
              id="Fill"
            />
            <path
              d="M10.9375,14.6875 C10.9375,15.1875 10.5,15.625 10,15.625 C9.5,15.625 9.0625,15.1875 9.0625,14.6875 C9.0625,14.1875 9.5,13.75 10,13.75 C10.5,13.75 10.9375,14.1875 10.9375,14.6875 Z M10.6875,5 L9.3125,5 L9.3125,11.875 L10.6875,11.875 L10.6875,5 Z"
              id="Inner-Fill"
              opacity="0"
            />
          </g>
        </g>
      </symbol>
      <symbol id="warning-alt-filled" viewBox="0 0 20 20" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Organization-/-Status-/-warning--alt--filled-/-20">
            <path
              d="M18.6795,17.836625 L10.5545,2.211625 C10.4469258,2.00476996 10.2331549,1.87499065 10,1.87499065 C9.76684509,1.87499065 9.55307419,2.00476996 9.4455,2.211625 L1.3205,17.836625 C1.21973775,18.0303733 1.22741116,18.2626642 1.3407402,18.4493403 C1.45406924,18.6360165 1.65661628,18.75 1.875,18.75 L18.125,18.75 C18.3433837,18.75 18.5459308,18.6360165 18.6592598,18.4493403 C18.7725888,18.2626642 18.7802622,18.0303733 18.6795,17.836625 Z M9.296875,6.875 L10.703125,6.875 L10.703125,13.125 L9.296875,13.125 L9.296875,6.875 Z M10,16.875 C9.48223305,16.875 9.0625,16.455267 9.0625,15.9375 C9.0625,15.419733 9.48223305,15 10,15 C10.517767,15 10.9375,15.419733 10.9375,15.9375 C10.9375,16.455267 10.517767,16.875 10,16.875 Z"
              id="Fill"
              fill="currentColor"
            />
            <path
              d="M9.296875,6.875 L10.703125,6.875 L10.703125,13.125 L9.296875,13.125 L9.296875,6.875 Z M10,16.875 C9.48223305,16.875 9.0625,16.455267 9.0625,15.9375 C9.0625,15.419733 9.48223305,15 10,15 C10.517767,15 10.9375,15.419733 10.9375,15.9375 C10.9375,16.455267 10.517767,16.875 10,16.875 Z"
              id="_inner-path_"
            />
          </g>
        </g>
      </symbol>
      <symbol id="trash-can" viewBox="0 0 20 20" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Actions-/-Formatting-/-trash-can-/-20" fill="currentColor">
            <path
              d="M7.5,7.5 L8.75,7.5 L8.75,15 L7.5,15 L7.5,7.5 Z M11.25,7.5 L12.5,7.5 L12.5,15 L11.25,15 L11.25,7.5 Z M2.5,3.75 L2.5,5 L3.75,5 L3.75,17.5 C3.75,18.1903559 4.30964406,18.75 5,18.75 L15,18.75 C15.6903559,18.75 16.25,18.1903559 16.25,17.5 L16.25,5 L17.5,5 L17.5,3.75 L2.5,3.75 Z M5,17.5 L5,5 L15,5 L15,17.5 L5,17.5 Z M7.5,1.25 L12.5,1.25 L12.5,2.5 L7.5,2.5 L7.5,1.25 Z"
              id="Fill"
            />
          </g>
        </g>
      </symbol>
      <symbol id="language" viewBox="0 0 20 20" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Actions-/-Formatting-/-language-/-20" fill="currentColor">
            <path
              d="M11.25,11.875 L15,11.875 L15,13.125 L11.25,13.125 L11.25,11.875 Z M11.25,9.375 L18.75,9.375 L18.75,10.625 L11.25,10.625 L11.25,9.375 Z M11.25,6.875 L18.75,6.875 L18.75,8.125 L11.25,8.125 L11.25,6.875 Z M5.24242105,18.7033684 C7.24326316,18.7033684 8.85,17.4149474 8.85,15.3383158 C8.85,13.6254737 7.86473684,12.9736842 6.86431579,12.2764211 C6.13673684,11.7762105 5.50010526,11.4427368 5.50010526,10.9576842 L5.50010526,10.7 L4.18136842,10.7 L4.18136842,10.9728421 C4.18136842,11.8368421 4.66642105,12.2157895 5.66684211,12.8675789 C6.78852632,13.58 7.53126316,14.1408421 7.53126316,15.3383158 C7.53126316,16.5206316 6.56115789,17.4301053 5.24242105,17.4301053 C3.98431579,17.4301053 2.98389474,16.5661053 2.96873684,15.3989474 C2.96873684,14.9896842 3.05968421,14.7168421 3.12031579,14.5804211 L1.92284211,14.2166316 C1.86221053,14.3682105 1.65,14.8532632 1.65,15.3989474 C1.65,17.3088421 3.19610526,18.7033684 5.24242105,18.7033684 Z M7.31631077,8.52962587 L8.75,8.52962587 L5.71704315,1.25 L4.28308921,1.25 L1.25,8.52962587 L2.68368923,8.52962587 L3.23535251,7.20605753 L6.76497838,7.20605753 L7.31631077,8.52962587 Z M3.7866849,5.88248919 L5,2.97063884 L6.21338128,5.88248919 L3.7866849,5.88248919 Z"
              id="Fill"
            />
          </g>
        </g>
      </symbol>
      <symbol id="user-avatar" viewBox="0 0 20 20" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Person-/-User-/-user--avatar-/-20" fill="currentColor">
            <path
              d="M10,5 C8.27411016,5 6.875,6.39911016 6.875,8.125 C6.875,9.85088984 8.27411016,11.25 10,11.25 C11.7258898,11.25 13.125,9.85088984 13.125,8.125 C13.125,7.29619847 12.7957599,6.50134249 12.2097087,5.91529131 C11.6236575,5.32924013 10.8288015,5 10,5 Z M10,10 C8.96446609,10 8.125,9.16053391 8.125,8.125 C8.125,7.08946609 8.96446609,6.25 10,6.25 C11.0355339,6.25 11.875,7.08946609 11.875,8.125 C11.8738289,9.16004845 11.0350484,9.99882893 10,10 L10,10 Z M10,1.25 C5.16750844,1.25 1.25,5.16750844 1.25,10 C1.25,14.8324916 5.16750844,18.75 10,18.75 C14.8324916,18.75 18.75,14.8324916 18.75,10 C18.7445579,5.16976441 14.8302356,1.25544206 10,1.25 Z M6.25,16.4853125 L6.25,15.625 C6.25113669,14.5899373 7.08993729,13.7511367 8.125,13.75 L11.875,13.75 C12.9100627,13.7511367 13.7488633,14.5899373 13.75,15.625 L13.75,16.4853125 C11.4330386,17.8382301 8.56696139,17.8382301 6.25,16.4853125 L6.25,16.4853125 Z M14.9953125,15.578625 C14.9694619,13.872924 13.5808944,12.5029079 11.875,12.5 L8.125,12.5 C6.41910556,12.5029079 5.03053815,13.872924 5.0046875,15.578625 C2.68431929,13.5067229 1.88381306,10.2174967 2.99258633,7.3110369 C4.10135959,4.40457706 6.88923048,2.48426596 10,2.48426596 C13.1107695,2.48426596 15.8986404,4.40457706 17.0074137,7.3110369 C18.1161869,10.2174967 17.3156807,13.5067229 14.9953125,15.578625 L14.9953125,15.578625 Z"
              id="Fill"
            />
          </g>
        </g>
      </symbol>

      <symbol id="link" viewBox="0 0 20 20" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Actions-/-Formatting-/-link-/-20" fill="currentColor">
            <path
              d="M18.28125,4.225 C17.577618,3.51892056 16.621818,3.12204287 15.625,3.12204287 C14.628182,3.12204287 13.672382,3.51892056 12.96875,4.225 L13.85625,5.1125 C14.48928,4.47947002 15.4119413,4.23224366 16.2766763,4.46394871 C17.1414113,4.69565376 17.8168462,5.3710887 18.0485513,6.23582372 C18.2802563,7.10055875 18.03303,8.02322004 17.4,8.65625 L12.4,13.65625 C11.7680865,14.28928 10.8464569,14.5371019 9.98228007,14.3063638 C9.11810328,14.0756256 8.44266834,13.4013819 8.21040507,12.5376138 C7.97814179,11.6738456 8.22433649,10.75178 8.85625,10.11875 L9.7375,9.23125 L8.85625,8.34375 L7.96875,9.23125 C7.26267056,9.93488202 6.86579287,10.890682 6.86579287,11.8875 C6.86579287,12.884318 7.26267056,13.840118 7.96875,14.54375 C8.6759318,15.2418735 9.63129515,15.6307626 10.625,15.6250631 C11.6257218,15.6291146 12.5865758,15.2330717 13.29375,14.525 L18.29375,9.525 C19.7525696,8.05746589 19.7469757,5.6856366 18.28125,4.225 Z M2.61875,15.5125 C2.14739087,15.0433291 1.88239934,14.405682 1.88239934,13.740625 C1.88239934,13.075568 2.14739087,12.4379209 2.61875,11.96875 L7.61875,6.96875 C8.08792092,6.49739087 8.72556802,6.23239934 9.390625,6.23239934 C10.055682,6.23239934 10.6933291,6.49739087 11.1625,6.96875 C11.6310316,7.44176713 11.8880628,8.08434515 11.875,8.75 C11.8788519,9.41756842 11.6155485,10.0589486 11.14375,10.53125 L9.81875,11.875 L10.70625,12.7625 L12.03125,11.4375 C13.4999822,9.96876774 13.4999822,7.58748228 12.03125,6.11875004 C10.5625177,4.6500178 8.18123226,4.65001778 6.7125,6.11875 L1.7125,11.11875 C1.00450105,11.82263 0.60640329,12.7797716 0.60640329,13.778125 C0.60640329,14.7764784 1.00450105,15.73362 1.7125,16.4375 C2.42426363,17.1302999 3.38181075,17.5124196 4.375,17.5 C5.37693904,17.5009566 6.3385739,17.105518 7.05,16.4 L6.1625,15.5125 C5.69332908,15.9838591 5.05568198,16.2488507 4.390625,16.2488507 C3.72556802,16.2488507 3.08792092,15.9838591 2.61875,15.5125 Z"
              id="Fill"
            />
          </g>
        </g>
      </symbol>
      <symbol id="mobile" viewBox="0 0 20 20" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Tools-/-Technology-/-mobile-/-20" fill="currentColor">
            <path
              d="M9.5,3.5 L10.5,3.5 C10.7761424,3.5 11,3.72385763 11,4 C11,4.27614237 10.7761424,4.5 10.5,4.5 L9.5,4.5 C9.22385763,4.5 9,4.27614237 9,4 C9,3.72385763 9.22385763,3.5 9.5,3.5 Z M14.75,1.5 C15.4400275,1.50079217 15.9992078,2.05997245 16,2.75 L16,2.75 L16,17.5 C15.9990704,18.1899705 15.4399705,18.7490704 14.75,18.75 L14.75,18.75 L5.25,18.75 C4.55997245,18.7492078 4.00079217,18.1900275 4,17.5 L4,17.5 L4,2.75 C4.00068904,2.05992967 4.55992967,1.50068904 5.25,1.5 L5.25,1.5 Z M5.25,17.5 L14.75,17.5 L14.75,2.75 L5.25,2.75 L5.25,17.5 Z"
              id="Fill"
            />
          </g>
        </g>
      </symbol>
      <symbol id="at" viewBox="0 0 20 20" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Tools-/-Technology-/-at-/-20" fill="currentColor">
            <path
              d="M10,1.875 C7.84103292,1.86158988 5.76660669,2.71330726 4.23995698,4.23995698 C2.71330726,5.76660669 1.86158988,7.84103292 1.87484401,10 L1.87484401,10.625 C1.87484401,15.1123136 5.51268641,18.75 10,18.75 L14.375,18.75 L14.375,17.5 L10,17.5 C6.20304234,17.5 3.125,14.4219577 3.125,10.625 L3.125,10 C3.11495528,8.17357583 3.83605753,6.41905043 5.12755398,5.12755398 C6.41905043,3.83605753 8.17357583,3.11495528 10,3.125 C11.8420843,3.06154424 13.6278793,3.76547491 14.9312022,5.0687978 C16.2345251,6.37212068 16.9384558,8.15791573 16.875,10 C16.875,12.24375 16,13.125 14.5875,13.125 C13.6,13.125 13.125,12.31875 13.125,11.25 L13.125,6.25 L11.875,6.25 L11.875,7.4625 C11.4306918,6.68031254 10.5862787,6.21226646 9.6875,6.25 C7.8007727,6.24306392 6.26375908,7.76331012 6.25,9.65 L6.25,10.975 C6.26375908,12.8616899 7.8007727,14.3819361 9.6875,14.375 C10.7526916,14.4062175 11.7348075,13.8022162 12.1875,12.8375 C12.6401848,13.7759174 13.5893541,14.3730929 14.63125,14.375 C16.55,14.375 18.125,13.125 18.125,10 C18.1733021,7.83066892 17.3327978,5.73586005 15.7984689,4.20153114 C14.26414,2.66720223 12.1693311,1.82669792 10,1.875 Z M11.875,10.975 C11.875,12.1831229 10.8956229,13.1625 9.6875,13.1625 C8.47937713,13.1625 7.5,12.1831229 7.5,10.975 L7.5,9.65 C7.5,8.44187711 8.47937711,7.4625 9.6875,7.4625 C10.8956229,7.4625 11.875,8.44187711 11.875,9.65 L11.875,10.975 Z"
              id="Fill"
            />
          </g>
        </g>
      </symbol>
      <symbol id="chat" viewBox="0 0 32 32" fill="currentColor">
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Tools-/-Technology-/-forum-/-32" fill="currentColor">
            <path
              d="M28,6 L8,6 C6.8,6 6,6.8 6,8 L6,22 C6,23.2 6.8,24 8,24 L16,24 L16,22 L8,22 L8,8 L28,8 L28,22 L20.8,22 L16,28.8 L17.6,30 L21.8,24 L28,24 C29.2,24 30,23.2 30,22 L30,8 C30,6.8 29.2,6 28,6 Z M4,18 L2,18 L2,5 C2,3.3 3.3,2 5,2 L18,2 L18,4 L5,4 C4.4,4 4,4.4 4,5 L4,18 Z"
              id="Fill"
            ></path>
          </g>
        </g>
      </symbol>
      <symbol id="arrow--right" viewBox="0 0 24 24" fill="currentColor">
        <title>Actions / Navigation / arrow--right / 24</title>
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Actions-/-Navigation-/-arrow--right-/-24" fill="currentColor">
            <polygon
              id="Fill"
              points="13.5 4.5 12.4275 5.54475 18.1125 11.25 3 11.25 3 12.75 18.1125 12.75 12.4275 18.42975 13.5 19.5 21 12"
            ></polygon>
          </g>
        </g>
      </symbol>
      <symbol id="chevron--left" viewBox="0 0 32 32" fill="currentColor">
        <title>Actions / Navigation / arrow--right / 24</title>
        <title>Actions / Navigation / chevron--left / 32</title>
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Actions-/-Navigation-/-chevron--left-/-32" fill="currentColor">
            <polygon
              id="Fill"
              points="9 16 19 6 20.4 7.4 11.8 16 20.4 24.6 19 26"
            ></polygon>
          </g>
        </g>
      </symbol>
      <symbol id="chevron--right" viewBox="0 0 32 32" fill="currentColor">
        <title>Actions / Navigation / chevron--right / 32</title>
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Actions-/-Navigation-/-chevron--right-/-32" fill="#000000">
            <polygon
              id="Fill"
              points="23 16 13 26 11.6 24.6 20.2 16 11.6 7.4 13 6"
            ></polygon>
          </g>
        </g>
      </symbol>
      <symbol id="chart--line-smooth" viewBox="0 0 48 48" fill="currentColor">
        <path
          id="Path_5890"
          data-name="Path 5890"
          d="M18.5,21.5c2.228,0,3.832,2.245,5.529,4.622C26,28.877,28.228,32,32,32c8.506,0,11.67-16.185,12-18l-2.952-.538C40.325,17.424,37.091,29,32,29c-2.228,0-3.832-2.245-5.529-4.622C24.5,21.623,22.272,18.5,18.5,18.5,12.221,18.5,7.333,29.606,5,36.143V2H2V41a3,3,0,0,0,3,3H44V41H6.566C8.832,33.286,13.945,21.5,18.5,21.5Z"
          transform="translate(1 1)"
          fill="#1152c6"
        />
        <rect
          id="_Transparent_Rectangle_"
          data-name="&lt;Transparent Rectangle&gt;"
          width="48"
          height="48"
          fill="none"
        />
      </symbol>
      <symbol id="purchase" viewBox="0 0 48 48" fill="currentColor">
        <path
          id="Path_5889"
          data-name="Path 5889"
          d="M41,6H5A3,3,0,0,0,2,9V33a3,3,0,0,0,3,3H41a3,3,0,0,0,3-3V9A3,3,0,0,0,41,6Zm0,3v4.5H5V9ZM5,33V16.5H41V33Z"
          transform="translate(1 3)"
          fill="#f59d45"
        />
        <rect
          id="Rectangle_793"
          data-name="Rectangle 793"
          width="15"
          height="3"
          transform="translate(9 30)"
          fill="#f59d45"
        />
        <rect
          id="_Transparent_Rectangle_"
          data-name="&lt;Transparent Rectangle&gt;"
          width="48"
          height="48"
          fill="none"
        />
      </symbol>
      <symbol id="receipt" viewBox="0 0 48 48" fill="currentColor">
        <rect
          id="Rectangle_788"
          data-name="Rectangle 788"
          width="3"
          height="3"
          transform="translate(32 24)"
          fill="#7f2e9a"
        />
        <rect
          id="Rectangle_789"
          data-name="Rectangle 789"
          width="12"
          height="3"
          transform="translate(14 24)"
          fill="#7f2e9a"
        />
        <rect
          id="Rectangle_790"
          data-name="Rectangle 790"
          width="3"
          height="3"
          transform="translate(32 18)"
          fill="#7f2e9a"
        />
        <rect
          id="Rectangle_791"
          data-name="Rectangle 791"
          width="12"
          height="3"
          transform="translate(14 18)"
          fill="#7f2e9a"
        />
        <rect
          id="Rectangle_792"
          data-name="Rectangle 792"
          width="21"
          height="3"
          transform="translate(14 12)"
          fill="#7f2e9a"
        />
        <path
          id="Path_5888"
          data-name="Path 5888"
          d="M35,2H8A3,3,0,0,0,5,5V42.5A1.5,1.5,0,0,0,6.5,44H8a1.5,1.5,0,0,0,1.2-.6L12.5,39l3.3,4.4a1.553,1.553,0,0,0,2.4,0L21.5,39l3.3,4.4a1.553,1.553,0,0,0,2.4,0L30.5,39l3.3,4.4A1.5,1.5,0,0,0,35,44h1.5A1.5,1.5,0,0,0,38,42.5V5a3,3,0,0,0-3-3Zm0,38-3.3-4.4a1.553,1.553,0,0,0-2.4,0L26,40l-3.3-4.4a1.553,1.553,0,0,0-2.4,0L17,40l-3.3-4.4a1.553,1.553,0,0,0-2.4,0L8,40V5H35Z"
          transform="translate(2.5 1)"
          fill="#7f2e9a"
        />
        <rect
          id="_Transparent_Rectangle_"
          data-name="&lt;Transparent Rectangle&gt;"
          width="48"
          height="48"
          fill="none"
        />
      </symbol>
      <symbol id="growth" viewBox="0 0 48 48" fill="currentColor">
        <path
          id="Path_5895"
          data-name="Path 5895"
          d="M29.134,8v3.015h9.928L26.119,23.958l-6.471-6.471a1.507,1.507,0,0,0-2.132,0L2,33l2.132,2.132,14.45-14.45,6.471,6.471a1.507,1.507,0,0,0,2.132,0L41.194,13.147v9.928h3.015V8Z"
          transform="translate(1.015 3.821)"
          fill="#008967"
        />
        <rect
          id="_Transparent_Rectangle_"
          data-name="&lt;Transparent Rectangle&gt;"
          width="48"
          height="48"
          fill="none"
        />
      </symbol>
      <symbol id="map" viewBox="0 0 48 48" fill="currentColor">
        <g id="map" transform="translate(0 -0.267)">
          <path
            id="Path_5897"
            data-name="Path 5897"
            d="M20.076,35.184,10.89,22.212A12.278,12.278,0,0,1,20.076,2,12.187,12.187,0,0,1,32.143,14.263,12.369,12.369,0,0,1,29.428,22Zm0-30.167a9.156,9.156,0,0,0-9.05,9.246A9.337,9.337,0,0,0,13.274,20.3l6.8,9.638L27.06,20.1a9.412,9.412,0,0,0,2.066-5.837,9.156,9.156,0,0,0-9.05-9.246Z"
            transform="translate(4.057 1.017)"
            fill="#f59d45"
          />
          <circle
            id="Ellipse_3"
            data-name="Ellipse 3"
            cx="3"
            cy="3"
            r="3"
            transform="translate(21 10.267)"
            fill="#f59d45"
          />
          <path
            id="Path_5898"
            data-name="Path 5898"
            d="M41.217,12H38.2v3.017h3.017V36.134H5.017V15.017H8.033V12H5.017A3.017,3.017,0,0,0,2,15.017V36.134A3.017,3.017,0,0,0,5.017,39.15h36.2a3.017,3.017,0,0,0,3.017-3.017V15.017A3.017,3.017,0,0,0,41.217,12Z"
            transform="translate(1.017 6.1)"
            fill="#f59d45"
          />
          <rect
            id="_Transparent_Rectangle_"
            data-name="&lt;Transparent Rectangle&gt;"
            width="48"
            height="48"
            transform="translate(0 0.267)"
            fill="none"
          />
        </g>
      </symbol>
      <symbol id="quick-pay" viewBox="0 0 48 48" fill="currentColor">
        <rect
          id="Rectangle_828"
          data-name="Rectangle 828"
          width="48"
          height="48"
          fill="none"
        />
        <g id="Group_3237" data-name="Group 3237" transform="translate(8 5)">
          <g id="Group_3239" data-name="Group 3239">
            <path
              id="Path_5909"
              data-name="Path 5909"
              d="M29.43,4.529H7.631C6.293,4.53,5,4.153,5,5.535v26.7a1.232,1.232,0,0,0,1.211,1.252H7.422a1.2,1.2,0,0,0,.969-.5l2.664-3.671,2.664,3.671a1.228,1.228,0,0,0,1.938,0l2.664-3.671,2.664,3.671a1.228,1.228,0,0,0,1.938,0l2.664-3.671,2.664,3.671a1.2,1.2,0,0,0,.969.5h1.211a1.232,1.232,0,0,0,1.211-1.252L32,5.535c0-1.382-1.233-1-2.57-1.006Zm-.208,25.615-2.664-3.671a1.228,1.228,0,0,0-1.938,0l-2.664,3.671-2.664-3.671a1.228,1.228,0,0,0-1.938,0l-2.664,3.671-2.664-3.671a1.228,1.228,0,0,0-1.938,0L7.422,30.144,7.631,7.032h21.8Z"
              transform="translate(-2 5.518)"
              fill="#1152c6"
              stroke="#1152c6"
              strokeWidth="0.5"
            />
            <g
              id="Rectangle_827"
              data-name="Rectangle 827"
              fill="none"
              stroke="#1152c6"
              strokeWidth="2.5"
            >
              <rect width="33" height="12.2" stroke="none" />
              <rect x="1.25" y="1.25" width="30.5" height="9.7" fill="none" />
            </g>
            <rect
              id="Rectangle_788"
              data-name="Rectangle 788"
              width="3"
              height="2"
              transform="translate(22 26)"
              fill="#1152c6"
            />
            <rect
              id="Rectangle_789"
              data-name="Rectangle 789"
              width="12"
              height="2"
              transform="translate(8 26)"
              fill="#1152c6"
            />
            <rect
              id="Rectangle_790"
              data-name="Rectangle 790"
              width="3"
              height="2"
              transform="translate(22 21)"
              fill="#1152c6"
            />
            <rect
              id="Rectangle_791"
              data-name="Rectangle 791"
              width="12"
              height="2"
              transform="translate(8 21)"
              fill="#1152c6"
            />
            <rect
              id="Rectangle_792"
              data-name="Rectangle 792"
              width="17"
              height="2"
              transform="translate(8 16)"
              fill="#1152c6"
            />
          </g>
        </g>
      </symbol>
      <symbol id="devices" viewBox="0 0 48 48" fill="currentColor">
        <path
          id="Path_5899"
          data-name="Path 5899"
          d="M14,38H5a3,3,0,0,1-3-3V17a3,3,0,0,1,3-3h9a3,3,0,0,1,3,3V35A3,3,0,0,1,14,38ZM5,17V35h9V17Z"
          transform="translate(1 7)"
          fill="#008967"
        />
        <path
          id="Path_5900"
          data-name="Path 5900"
          d="M40,4H7A3,3,0,0,0,4,7v9H7V7H40V28H19v3h3v6H19v3H32.5V37H25V31H40a3,3,0,0,0,3-3V7A3,3,0,0,0,40,4Z"
          transform="translate(2 2)"
          fill="#008967"
        />
        <rect
          id="_Transparent_Rectangle_"
          data-name="&lt;Transparent Rectangle&gt;"
          width="48"
          height="48"
          fill="none"
        />
      </symbol>
      <symbol id="finance" viewBox="0 0 48 48" fill="currentColor">
        <rect
          id="Rectangle_822"
          data-name="Rectangle 822"
          width="42"
          height="3"
          transform="translate(3 42)"
          fill="#f59d45"
        />
        <path
          id="Path_5901"
          data-name="Path 5901"
          d="M38.5,15.5A1.5,1.5,0,0,0,40,14V9.5a1.5,1.5,0,0,0-.99-1.41l-16.5-6a1.5,1.5,0,0,0-1.02,0l-16.5,6A1.5,1.5,0,0,0,4,9.5V14a1.5,1.5,0,0,0,1.5,1.5H7V35H4v3H40V35H37V15.5ZM7,10.55,22,5.09l15,5.46V12.5H7ZM25,35H19V15.5h6ZM10,15.5h6V35H10ZM34,35H28V15.5h6Z"
          transform="translate(2 1)"
          fill="#f59d45"
        />
        <rect
          id="_Transparent_Rectangle_"
          data-name="&lt;Transparent Rectangle&gt;"
          width="48"
          height="48"
          fill="none"
        />
      </symbol>
      <symbol id="apps" viewBox="0 0 48 48" fill="currentColor">
        <path
          id="Path_5910"
          data-name="Path 5910"
          d="M11.429,5.143v6.286H5.143V5.143ZM14.571,2H2V14.571H14.571ZM27.143,5.143v6.286H20.857V5.143ZM30.286,2H17.714V14.571H30.286ZM42.857,5.143v6.286H36.571V5.143ZM46,2H33.429V14.571H46ZM11.429,20.857v6.286H5.143V20.857Zm3.143-3.143H2V30.286H14.571Zm12.571,3.143v6.286H20.857V20.857Zm3.143-3.143H17.714V30.286H30.286Zm12.571,3.143v6.286H36.571V20.857ZM46,17.714H33.429V30.286H46ZM11.429,36.571v6.286H5.143V36.571Zm3.143-3.143H2V46H14.571Zm12.571,3.143v6.286H20.857V36.571Zm3.143-3.143H17.714V46H30.286Zm12.571,3.143v6.286H36.571V36.571ZM46,33.429H33.429V46H46Z"
          transform="translate(0 0)"
          fill="#7f2e9a"
        />
        <rect
          id="_Transparent_Rectangle_"
          data-name="&lt;Transparent Rectangle&gt;"
          width="48"
          height="48"
          fill="none"
        />
      </symbol>
      <symbol
        id="automatic"
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <path
          id="Path_5911"
          data-name="Path 5911"
          d="M28.933,16H22.978A2.981,2.981,0,0,0,20,18.978V36.844h2.978V29.4h5.955v7.444h2.978V18.978A2.981,2.981,0,0,0,28.933,16ZM22.978,26.422V18.978h5.955v7.444Z"
          transform="translate(10.06 7.896)"
          fill="#7f2e9a"
        />
        <path
          id="Path_5912"
          data-name="Path 5912"
          d="M21.866,30.422A16.357,16.357,0,0,1,8.146,22.978h7.765V20H4V31.911H6.978V26.424A19.391,19.391,0,0,0,21.866,33.4Z"
          transform="translate(2.238 9.851)"
          fill="#7f2e9a"
        />
        <path
          id="Path_5913"
          data-name="Path 5913"
          d="M28.31,13.422h7.765a16.373,16.373,0,0,0-30.1,8.933H3A19.362,19.362,0,0,1,37.243,9.975V4.489h2.978V16.4H28.31Z"
          transform="translate(1.749 1.541)"
          fill="#7f2e9a"
        />
        <rect
          id="_Transparent_Rectangle_"
          data-name="&lt;Transparent Rectangle&gt;"
          width="48"
          height="48"
          fill="none"
        />
      </symbol>

      <symbol
        id="ibm-security"
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <path
          id="IBM_Security_Shield"
          data-name="IBM Security Shield"
          d="M21.826,0,4,8.88V29.775a17.826,17.826,0,1,0,35.651,0V8.88ZM31.971,40.574l-2.665,1.982A14.858,14.858,0,0,1,6.971,29.775V10.71l14.855-7.4,14.855,7.4v5.57L21.826,8.88V12.19l14.855,7.4v5.57l-14.855-7.4V21.07l14.855,7.4v1.305a14.713,14.713,0,0,1-.55,3.992l-14.3-7.126v3.309l13.213,6.583a14.892,14.892,0,0,1-3.068,4.042Z"
          transform="translate(1.942 0)"
          fill="#76ba19"
        />
        <rect
          id="_Transparent_Rectangle_"
          data-name="&lt;Transparent Rectangle&gt;"
          width="48"
          height="48"
          fill="none"
        />
      </symbol>
      <symbol
        id="cloud--auditing"
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <path
          id="Path_5927"
          data-name="Path 5927"
          d="M41,22v9H5V7H23V4H5A3,3,0,0,0,2,7V31a3,3,0,0,0,3,3H17v6H11v3H35V40H29V34H41a3,3,0,0,0,3-3V22ZM26,40H20V34h6Z"
          transform="translate(1 2)"
          fill="#f59d45"
        />
        <path
          id="Path_5928"
          data-name="Path 5928"
          d="M23.5,20.25,16,12.81l2.385-2.355,5.115,5.07L34.615,4.5,37,6.87Z"
          transform="translate(8 2.25)"
          fill="#f59d45"
        />
        <rect
          id="_Transparent_Rectangle_"
          data-name="&lt;Transparent Rectangle&gt;"
          width="48"
          height="48"
          fill="none"
        />
      </symbol>
      <symbol
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <g id="certificate--check" transform="translate(0 0.215)">
          <rect
            id="Rectangle_839"
            data-name="Rectangle 839"
            width="9"
            height="3"
            transform="translate(9 23.785)"
            fill="#0b8967"
          />
          <rect
            id="Rectangle_840"
            data-name="Rectangle 840"
            width="15"
            height="3"
            transform="translate(9 17.785)"
            fill="#0b8967"
          />
          <rect
            id="Rectangle_841"
            data-name="Rectangle 841"
            width="15"
            height="3"
            transform="translate(9 11.785)"
            fill="#0b8967"
          />
          <path
            id="Path_5932"
            data-name="Path 5932"
            d="M19.919,36.852H4.987V6.987H40.825V21.919h2.987V6.987A2.987,2.987,0,0,0,40.825,4H4.987A2.987,2.987,0,0,0,2,6.987V36.852a2.987,2.987,0,0,0,2.987,2.987H19.919Z"
            transform="translate(0.987 1.973)"
            fill="#0b8967"
          />
          <path
            id="Path_5933"
            data-name="Path 5933"
            d="M23.973,28.841l-3.868-3.868L18,27.079l5.973,5.973L35.919,21.106,33.814,19Z"
            transform="translate(8.879 9.372)"
            fill="#0b8967"
          />
          <rect
            id="_Transparent_Rectangle_"
            data-name="&lt;Transparent Rectangle&gt;"
            width="48"
            height="48"
            transform="translate(0 -0.215)"
            fill="none"
          />
        </g>
      </symbol>
      <symbol
        id="money"
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <rect
          id="Rectangle_837"
          data-name="Rectangle 837"
          width="42"
          height="3"
          transform="translate(3 33)"
          fill="#2babd9"
        />
        <rect
          id="Rectangle_838"
          data-name="Rectangle 838"
          width="42"
          height="3"
          transform="translate(3 39)"
          fill="#2babd9"
        />
        <path
          id="Path_5920"
          data-name="Path 5920"
          d="M24.976,10a2.976,2.976,0,1,0,2.976,2.976A2.976,2.976,0,0,0,24.976,10Z"
          transform="translate(10.74 4.882)"
          fill="#2babd9"
        />
        <path
          id="Path_5921"
          data-name="Path 5921"
          d="M17.953,19.905a5.953,5.953,0,1,1,5.953-5.953,5.953,5.953,0,0,1-5.953,5.953Zm0-8.929a2.976,2.976,0,1,0,2.976,2.976,2.976,2.976,0,0,0-2.976-2.976Z"
          transform="translate(5.858 3.905)"
          fill="#2babd9"
        />
        <path
          id="Path_5922"
          data-name="Path 5922"
          d="M8.976,10a2.976,2.976,0,1,0,2.976,2.976A2.976,2.976,0,0,0,8.976,10Z"
          transform="translate(2.929 4.882)"
          fill="#2babd9"
        />
        <path
          id="Path_5923"
          data-name="Path 5923"
          d="M40.692,27.811H4.976A2.984,2.984,0,0,1,2,24.834V6.976A2.984,2.984,0,0,1,4.976,4H40.692a2.984,2.984,0,0,1,2.976,2.976V24.834a2.98,2.98,0,0,1-2.976,2.976Zm0-20.834H4.976V24.834H40.692Z"
          transform="translate(0.976 1.953)"
          fill="#2babd9"
        />
        <rect
          id="_Transparent_Rectangle_"
          data-name="&lt;Transparent Rectangle&gt;"
          width="48"
          height="48"
          fill="none"
        />
      </symbol>

      <symbol
        xmlns="http://www.w3.org/2000/svg"
        width="56.353"
        height="56.353"
        viewBox="0 0 56.353 56.353"
      >
        <g
          id="cashback"
          data-name="Group 3406"
          transform="matrix(-0.766, -0.643, 0.643, -0.766, 30.642, 56.358)"
        >
          <path
            id="Combined-Shape"
            d="M8.813,35.719a19.17,19.17,0,0,0,24.147.01,19.083,19.083,0,0,0,3.223-3.621A19.646,19.646,0,0,0,35.1,7.49L34.764,7.1l-.041-.008-.131-.15A20.7,20.7,0,0,0,25.052,1a9.506,9.506,0,0,0-1.52-.5A19.349,19.349,0,0,0,9.674,2.481q-.137.082-.269.169l-.01-.015A18.433,18.433,0,0,0,6.6,4.561a18.653,18.653,0,0,0-4.27,23.29,1.718,1.718,0,0,0,2.595.61L15.052,20a1.661,1.661,0,0,0,.172-2.356l-2.5-2.963,13.388.179-2.152,13.17-2.628-3.111a1.693,1.693,0,0,0-2.274-.339l-.1.074L8.687,33.189A1.641,1.641,0,0,0,8.813,35.719ZM26.457,12.124l.015-.065-16.137-.216A5.888,5.888,0,0,1,8.97,8.928l-.028-.3a4.152,4.152,0,0,1,2.2-3.718A16.53,16.53,0,0,1,22.954,3.228l.178.046-.025.118a4.611,4.611,0,0,1,3.405,3.332,11.864,11.864,0,0,1,.117,4.434,8.4,8.4,0,0,1-.147.863Zm2.6,20.36a6.385,6.385,0,0,1-2.678-1.7l-.014-.02,2.99-18.288A15.179,15.179,0,0,0,29.222,6l-.013-.041.17.112a17.946,17.946,0,0,1,3.577,3.209l.333.39.017,0,.4.514a16.75,16.75,0,0,1,.077,20.377l-.343.424a7.586,7.586,0,0,1-.708.7A3.957,3.957,0,0,1,29.061,32.484ZM4.184,25.378l-.052-.111A16,16,0,0,1,5.99,9.3l.176-.225A8.453,8.453,0,0,0,8.8,14.315l.125.1,3.46,4.1ZM28.107,35.544a16.47,16.47,0,0,1-16.358-1.049l-.1-.071,8.322-6.917,4.137,4.9a9.247,9.247,0,0,0,4.249,2.918l.112.031Z"
            transform="translate(0 0)"
            fill="#e82d76"
            fillRule="evenodd"
          />
        </g>
      </symbol>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44"
      >
        <g
          id="ibm-security-mobile"
          data-name="Group 6023"
          transform="translate(-4594 -3157)"
        >
          <circle
            id="Ellipse_160"
            data-name="Ellipse 160"
            cx="22"
            cy="22"
            r="22"
            transform="translate(4594 3157)"
            fill="#76ba19"
          />
          <g id="ibm-security" transform="translate(4604 3167)">
            <path
              id="IBM_Security_Shield"
              data-name="IBM Security Shield"
              d="M12.9,0,4,4.433V14.862a8.9,8.9,0,1,0,17.795,0V4.433Zm5.064,20.252-1.33.989a7.416,7.416,0,0,1-11.149-6.38V5.346L12.9,1.652l7.415,3.694v2.78L12.9,4.433V6.085l7.415,3.694v2.78L12.9,8.865v1.652l7.415,3.694v.652a7.344,7.344,0,0,1-.274,1.992L12.9,13.3v1.652l6.6,3.286a7.433,7.433,0,0,1-1.531,2.018Z"
              transform="translate(-1.034 0)"
              fill="#fff"
            />
            <rect
              id="_Transparent_Rectangle_"
              data-name="&lt;Transparent Rectangle&gt;"
              width="24"
              height="24"
              fill="none"
            />
          </g>
        </g>
      </svg>

      <symbol
        id="icn-success"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="currentcolor"
      >
        <defs>
          <clipPath id="clipPath">
            <path
              id="Path_7224"
              data-name="Path 7224"
              d="M17.155,0l1.074,1.074L6.836,12.467,0,5.631,1.074,4.557l5.762,5.761Z"
              fill="#fff"
            />
          </clipPath>
        </defs>
        <g
          id="Group_5971"
          data-name="Group 5971"
          transform="translate(-1007 -221.467)"
        >
          <circle
            id="Ellipse_147"
            data-name="Ellipse 147"
            cx="24"
            cy="24"
            r="24"
            transform="translate(1007 221.467)"
            fill="#008967"
          />
          <g id="_32" data-name=" 32" transform="translate(1022.191 238.928)">
            <g id="Group_5849" data-name="Group 5849" clipPath="url(#clipPath)">
              <path
                id="Path_7223"
                data-name="Path 7223"
                d="M0,0H18.229V12.467H0Z"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </symbol>
      <symbol
        id="error"
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="currentcolor"
      >
        <g
          id="Group_5891"
          data-name="Group 5891"
          transform="translate(-928 -256)"
        >
          <g
            id="Group_5850"
            data-name="Group 5850"
            transform="translate(-79 35)"
          >
            <circle
              id="Ellipse_147"
              data-name="Ellipse 147"
              cx="32"
              cy="32"
              r="32"
              transform="translate(1007 221)"
              fill="#f35815"
            />
          </g>
          <g id="warning" transform="translate(944 272)">
            <path
              id="Path_7241"
              data-name="Path 7241"
              d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,26A12,12,0,1,1,28,16,12,12,0,0,1,16,28Z"
              fill="#fff"
            />
            <rect
              id="Rectangle_1564"
              data-name="Rectangle 1564"
              width="2"
              height="11"
              transform="translate(15 8)"
              fill="#fff"
            />
            <path
              id="Path_7242"
              data-name="Path 7242"
              d="M16,22a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,16,22Z"
              fill="#fff"
            />
            <rect
              id="_Transparent_Rectangle_"
              data-name="&lt;Transparent Rectangle&gt;"
              width="32"
              height="32"
              fill="none"
            />
          </g>
        </g>
      </symbol>
      <symbol
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="12"
        viewBox="0 0 16 12"
      >
        <g
          id="menu-outline"
          data-name="Group 6071"
          transform="translate(-16 -45)"
        >
          <rect
            id="Rectangle_1681"
            data-name="Rectangle 1681"
            width="16"
            height="2"
            transform="translate(16 55)"
            fill="#161616"
          />
          <rect
            id="Rectangle_1682"
            data-name="Rectangle 1682"
            width="16"
            height="2"
            transform="translate(16 50)"
            fill="#161616"
          />
          <rect
            id="Rectangle_1683"
            data-name="Rectangle 1683"
            width="16"
            height="2"
            transform="translate(16 45)"
            fill="#161616"
          />
        </g>
      </symbol>
      <symbol
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
      >
        <g id="subtract" transform="translate(0 0.443)">
          <rect
            id="Rectangle_1638"
            data-name="Rectangle 1638"
            width="16"
            height="2"
            transform="translate(8 14.557)"
            fill="#6f6f6f"
          />
          <rect
            id="_x3C_Transparent_Rectangle_x3E_"
            width="32"
            height="32"
            transform="translate(0 -0.443)"
            fill="none"
          />
        </g>
      </symbol>

      <symbol
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
      >
        <g id="add" transform="translate(0 0.364)">
          <path
            id="Path_5887"
            data-name="Path 5887"
            d="M17,15V8H15v7H8v2h7v7h2V17h7V15Z"
            transform="translate(0 0.298)"
            fill="#6f6f6f"
          />
          <rect
            id="Rectangle_1637"
            data-name="Rectangle 1637"
            width="32"
            height="32"
            transform="translate(0 -0.364)"
            fill="none"
          />
        </g>
      </symbol>

      <symbol
        xmlns="http://www.w3.org/2000/svg"
        width="18.455"
        height="19.454"
        viewBox="0 0 18.455 19.454"
      >
        <path
          id="Path_6026"
          data-name="Path 6026"
          d="M12.226,3A9.1,9.1,0,0,0,5.685,5.725,9.381,9.381,0,0,0,3,12.365v.72a9.3,9.3,0,0,0,9.226,9.366h4.968V21.01H12.226a7.866,7.866,0,0,1-7.807-7.925v-.72A7.943,7.943,0,0,1,6.693,6.748,7.705,7.705,0,0,1,12.226,4.44a7.491,7.491,0,0,1,5.6,2.241,7.728,7.728,0,0,1,2.207,5.684c0,2.586-.994,3.6-2.6,3.6-1.121,0-1.661-.929-1.661-2.161V8.042H14.355v1.4a2.717,2.717,0,0,0-2.484-1.4,3.918,3.918,0,0,0-3.9,3.919v1.527a3.918,3.918,0,0,0,3.9,3.919,3.033,3.033,0,0,0,2.839-1.772,3.084,3.084,0,0,0,2.775,1.772c2.179,0,3.967-1.441,3.967-5.043A9.238,9.238,0,0,0,18.81,5.681,8.957,8.957,0,0,0,12.226,3Zm2.129,10.49a2.484,2.484,0,1,1-4.968,0V11.961a2.484,2.484,0,1,1,4.968,0Z"
          transform="translate(-3 -2.997)"
        />
      </symbol>

      <symbol
        xmlns="http://www.w3.org/2000/svg"
        width="17.469"
        height="17.458"
        viewBox="0 0 17.469 17.458"
      >
        <path
          id="Path_6004"
          data-name="Path 6004"
          d="M18.444,20.458H18.33C5.133,19.7,3.259,8.566,3,5.169A2.015,2.015,0,0,1,4.851,3h3.7A1.343,1.343,0,0,1,9.8,3.846l1.021,2.511a1.343,1.343,0,0,1-.3,1.45L9.1,9.251a6.292,6.292,0,0,0,5.091,5.1l1.457-1.444a1.343,1.343,0,0,1,1.457-.275l2.532,1.014a1.343,1.343,0,0,1,.826,1.249v3.545A2.015,2.015,0,0,1,18.444,20.458ZM5.012,4.343a.672.672,0,0,0-.672.671v.054C4.649,9.043,6.63,18.443,18.4,19.115a.672.672,0,0,0,.712-.631V14.9l-2.532-1.014L14.656,15.8l-.322-.04C8.491,15.026,7.7,9.184,7.7,9.124L7.658,8.8,9.565,6.874,8.558,4.343Z"
          transform="translate(-2.99 -3)"
          fill="#161616"
        />
      </symbol>
      <symbol
        id="pay"
        xmlns="http://www.w3.org/2000/svg"
        width="57.649"
        height="30.342"
        viewBox="0 0 57.649 30.342"
      >
        <g id="Combined-Shape-2">
          <path
            id="Combined-Shape"
            d="M45.424,6.739l3.4,9.771,2.627-9.771h6.2L51.89,23.316c-1.785,5.121-4.984,7.244-10.171,7.008V25.068c2.391,0,3.435-.505,4.176-2.022L39.16,6.739ZM26.59,6.267a5.894,5.894,0,0,1,4.782,2.022V6.739h5.59V24.057h-5.59V22.035a5.894,5.894,0,0,1-4.782,2.022c-4.513,0-7.914-3.841-7.914-8.9S22.077,6.267,26.59,6.267Zm-20.9,4.441v.915a.892.892,0,0,0,1.4.733L12.22,8.792a.892.892,0,0,0,0-1.466L7.091,3.763a.892.892,0,0,0-1.4.733v.858H0V0H9.16A8.119,8.119,0,0,1,17.58,8.255,8.119,8.119,0,0,1,9.16,16.51H6.028v6.71H0V10.709Zm22.147.781A3.432,3.432,0,0,0,24.3,15.162a3.539,3.539,0,1,0,7.072,0A3.432,3.432,0,0,0,27.836,11.489Z"
            fill="#e3192a"
            fillRule="evenodd"
          />
        </g>
      </symbol>
      <symbol
        id="information"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
      >
        <path
          id="Path_6030"
          data-name="Path 6030"
          d="M15.178,19.085V14H12.636v1.271h1.271v3.813H12v1.271h5.085V19.085Z"
          transform="translate(-4.373 -5.102)"
          fill="#6f6f6f"
        />
        <path
          id="Path_6031"
          data-name="Path 6031"
          d="M15.453,8a.953.953,0,1,0,.953.953A.953.953,0,0,0,15.453,8Z"
          transform="translate(-5.284 -2.915)"
          fill="#6f6f6f"
        />
        <path
          id="Path_6032"
          data-name="Path 6032"
          d="M10.9,19.8a8.9,8.9,0,1,1,8.9-8.9,8.9,8.9,0,0,1-8.9,8.9Zm0-16.525A7.627,7.627,0,1,0,18.525,10.9,7.627,7.627,0,0,0,10.9,3.271Z"
          transform="translate(-0.729 -0.729)"
          fill="#6f6f6f"
        />
        <rect
          id="_Transparent_Rectangle_"
          data-name="&lt;Transparent Rectangle&gt;"
          width="20"
          height="20"
          fill="none"
        />
      </symbol>
      <symbol
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
      >
        <g
          id="close-outline"
          data-name="Actions-/-Navigation-/-close-/-24"
          transform="translate(-5 -6)"
        >
          <path
            id="Fill"
            d="M19,6.05,17.95,5,12,10.95,6.05,5,5,6.05,10.95,12,5,17.95,6.05,19,12,13.05,17.95,19,19,17.95,13.05,12Z"
            transform="translate(0 1)"
            fillRule="evenodd"
          />
        </g>
      </symbol>
      <symbol
        id="group"
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <path
          id="Path_6038"
          data-name="Path 6038"
          d="M37.9,33.944H34.917V29.465a4.479,4.479,0,0,0-4.479-4.479H24.465a4.479,4.479,0,0,0-4.479,4.479v4.479H17V29.465A7.465,7.465,0,0,1,24.465,22h5.972A7.465,7.465,0,0,1,37.9,29.465Z"
          transform="translate(8.382 10.847)"
          fill="#7f2e9a"
        />
        <path
          id="Path_6039"
          data-name="Path 6039"
          d="M26.465,12.986a4.479,4.479,0,1,1-4.479,4.479,4.479,4.479,0,0,1,4.479-4.479m0-2.986a7.465,7.465,0,1,0,7.465,7.465A7.465,7.465,0,0,0,26.465,10Z"
          transform="translate(9.368 4.93)"
          fill="#7f2e9a"
        />
        <path
          id="Path_6040"
          data-name="Path 6040"
          d="M21.9,25.944H18.917V21.465a4.479,4.479,0,0,0-4.479-4.479H8.465a4.479,4.479,0,0,0-4.479,4.479v4.479H1V21.465A7.465,7.465,0,0,1,8.465,14h5.972A7.465,7.465,0,0,1,21.9,21.465Z"
          transform="translate(0.493 6.903)"
          fill="#7f2e9a"
        />
        <path
          id="Path_6041"
          data-name="Path 6041"
          d="M10.465,4.986A4.479,4.479,0,1,1,5.986,9.465a4.479,4.479,0,0,1,4.479-4.479m0-2.986A7.465,7.465,0,1,0,17.93,9.465,7.465,7.465,0,0,0,10.465,2Z"
          transform="translate(1.479 0.986)"
          fill="#7f2e9a"
        />
        <rect
          id="_Transparent_Rectangle_"
          data-name="&lt;Transparent Rectangle&gt;"
          width="48"
          height="48"
          fill="none"
        />
      </symbol>
      <symbol
        id="money-green"
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <rect
          id="Rectangle_837"
          data-name="Rectangle 837"
          width="42"
          height="3"
          transform="translate(3 33)"
          fill="#76ba19"
        />
        <rect
          id="Rectangle_838"
          data-name="Rectangle 838"
          width="42"
          height="3"
          transform="translate(3 39)"
          fill="#76ba19"
        />
        <path
          id="Path_5920"
          data-name="Path 5920"
          d="M24.976,10a2.976,2.976,0,1,0,2.976,2.976A2.976,2.976,0,0,0,24.976,10Z"
          transform="translate(10.74 4.882)"
          fill="#76ba19"
        />
        <path
          id="Path_5921"
          data-name="Path 5921"
          d="M17.953,19.905a5.953,5.953,0,1,1,5.953-5.953,5.953,5.953,0,0,1-5.953,5.953Zm0-8.929a2.976,2.976,0,1,0,2.976,2.976,2.976,2.976,0,0,0-2.976-2.976Z"
          transform="translate(5.858 3.905)"
          fill="#76ba19"
        />
        <path
          id="Path_5922"
          data-name="Path 5922"
          d="M8.976,10a2.976,2.976,0,1,0,2.976,2.976A2.976,2.976,0,0,0,8.976,10Z"
          transform="translate(2.929 4.882)"
          fill="#76ba19"
        />
        <path
          id="Path_5923"
          data-name="Path 5923"
          d="M40.692,27.811H4.976A2.984,2.984,0,0,1,2,24.834V6.976A2.984,2.984,0,0,1,4.976,4H40.692a2.984,2.984,0,0,1,2.976,2.976V24.834a2.98,2.98,0,0,1-2.976,2.976Zm0-20.834H4.976V24.834H40.692Z"
          transform="translate(0.976 1.953)"
          fill="#76ba19"
        />
        <rect
          id="_Transparent_Rectangle_"
          data-name="&lt;Transparent Rectangle&gt;"
          width="48"
          height="48"
          fill="none"
        />
      </symbol>
      <symbol
        id="partnership"
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <path
          id="Path_6042"
          data-name="Path 6042"
          d="M9.979,12.958a5.979,5.979,0,1,1,5.979-5.979A5.979,5.979,0,0,1,9.979,12.958Zm0-8.968a2.989,2.989,0,1,0,2.989,2.989A2.989,2.989,0,0,0,9.979,3.989Z"
          transform="translate(1.979 0.495)"
          fill="#0b8967"
        />
        <path
          id="Path_6043"
          data-name="Path 6043"
          d="M25.979,12.958a5.979,5.979,0,1,1,5.979-5.979A5.979,5.979,0,0,1,25.979,12.958Zm0-8.968a2.989,2.989,0,1,0,2.989,2.989A2.989,2.989,0,0,0,25.979,3.989Z"
          transform="translate(9.894 0.495)"
          fill="#0b8967"
        />
        <path
          id="Path_6044"
          data-name="Path 6044"
          d="M37.873,39.894H31.894A2.989,2.989,0,0,1,28.9,36.9V26.442h2.989V36.9h5.979V23.452h2.989V14.484a1.5,1.5,0,0,0-1.495-1.495h-9.6L22.926,24.947,16.08,12.989h-9.6a1.5,1.5,0,0,0-1.495,1.495v8.968H7.979V36.9h5.979V26.442h2.989V36.9a2.989,2.989,0,0,1-2.989,2.989H7.979A2.989,2.989,0,0,1,4.989,36.9V26.442A2.989,2.989,0,0,1,2,23.452V14.484A4.484,4.484,0,0,1,6.484,10h11.33l5.112,8.968L28.038,10h11.33a4.484,4.484,0,0,1,4.484,4.484v8.968a2.989,2.989,0,0,1-2.989,2.989V36.9A2.989,2.989,0,0,1,37.873,39.894Z"
          transform="translate(0.989 4.947)"
          fill="#0b8967"
        />
        <rect
          id="_Transparent_Rectangle_"
          data-name="&lt;Transparent Rectangle&gt;"
          width="48"
          height="48"
          fill="none"
        />
      </symbol>
      <symbol
        id="qr-code"
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <rect
          id="Rectangle_1502"
          data-name="Rectangle 1502"
          width="3"
          height="3"
          transform="translate(36 42) rotate(-90)"
          fill="#1152c6"
        />
        <rect
          id="Rectangle_1503"
          data-name="Rectangle 1503"
          width="3"
          height="3"
          transform="translate(27 36) rotate(-90)"
          fill="#1152c6"
        />
        <path
          id="Path_6045"
          data-name="Path 6045"
          d="M18,31.969h5.969V28.985H20.985V26H18Z"
          transform="translate(8.861 12.8)"
          fill="#1152c6"
        />
        <rect
          id="Rectangle_1504"
          data-name="Rectangle 1504"
          width="6"
          height="3"
          transform="translate(39 39) rotate(-90)"
          fill="#1152c6"
        />
        <path
          id="Path_6046"
          data-name="Path 6046"
          d="M28.985,26h2.985v5.969H26V28.985h2.985Z"
          transform="translate(12.8 12.8)"
          fill="#1152c6"
        />
        <path
          id="Path_6047"
          data-name="Path 6047"
          d="M26,20.985V18h5.969v5.969H28.985V20.985Z"
          transform="translate(12.8 8.861)"
          fill="#1152c6"
        />
        <path
          id="Path_6048"
          data-name="Path 6048"
          d="M25.969,20H22.985v5.969H20v2.985h5.969Z"
          transform="translate(9.846 9.846)"
          fill="#1152c6"
        />
        <rect
          id="Rectangle_1505"
          data-name="Rectangle 1505"
          width="3"
          height="6"
          transform="translate(27 30) rotate(-90)"
          fill="#1152c6"
        />
        <rect
          id="Rectangle_1506"
          data-name="Rectangle 1506"
          width="6"
          height="6"
          transform="translate(9 33)"
          fill="#1152c6"
        />
        <path
          id="Path_6049"
          data-name="Path 6049"
          d="M19.908,35.908H2V18H19.908ZM4.985,32.923H16.923V20.985H4.985Z"
          transform="translate(0.985 8.861)"
          fill="#1152c6"
        />
        <rect
          id="Rectangle_1507"
          data-name="Rectangle 1507"
          width="6"
          height="6"
          transform="translate(33 9)"
          fill="#1152c6"
        />
        <path
          id="Path_6050"
          data-name="Path 6050"
          d="M35.908,19.908H18V2H35.908ZM20.985,16.923H32.923V4.985H20.985Z"
          transform="translate(8.861 0.985)"
          fill="#1152c6"
        />
        <rect
          id="Rectangle_1508"
          data-name="Rectangle 1508"
          width="6"
          height="6"
          transform="translate(9 9)"
          fill="#1152c6"
        />
        <path
          id="Path_6051"
          data-name="Path 6051"
          d="M19.908,19.908H2V2H19.908ZM4.985,16.923H16.923V4.985H4.985Z"
          transform="translate(0.985 0.985)"
          fill="#1152c6"
        />
        <rect
          id="_Transparent_Rectangle_"
          data-name="&lt;Transparent Rectangle&gt;"
          width="48"
          height="48"
          fill="none"
        />
      </symbol>
      <symbol
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44"
      >
        <g
          id="automatic-mobile"
          data-name="Group 6021"
          transform="translate(-138 -1189)"
        >
          <g
            id="Group_6020"
            data-name="Group 6020"
            transform="translate(-4523 -1866)"
          >
            <g
              id="Group_6019"
              data-name="Group 6019"
              transform="translate(19834 5749)"
            >
              <g id="Group_5985" data-name="Group 5985">
                <circle
                  id="Ellipse_159"
                  data-name="Ellipse 159"
                  cx="22"
                  cy="22"
                  r="22"
                  transform="translate(-15173 -2694)"
                  fill="#7f2e9a"
                />
                <g id="automatic" transform="translate(-15163 -2684)">
                  <path
                    id="Path_5911"
                    data-name="Path 5911"
                    d="M24.449,16H21.483A1.485,1.485,0,0,0,20,17.483v8.9h1.483V22.674h2.966v3.708h1.483v-8.9A1.485,1.485,0,0,0,24.449,16Zm-2.966,5.191V17.483h2.966v3.708Z"
                    transform="translate(-5.029 -4.099)"
                    fill="#fff"
                  />
                  <path
                    id="Path_5912"
                    data-name="Path 5912"
                    d="M12.9,25.191a8.147,8.147,0,0,1-6.834-3.708H9.932V20H4v5.932H5.483V23.2A9.658,9.658,0,0,0,12.9,26.674Z"
                    transform="translate(-0.893 -5.132)"
                    fill="#fff"
                  />
                  <path
                    id="Path_5913"
                    data-name="Path 5913"
                    d="M15.606,8.191h3.867A8.155,8.155,0,0,0,4.483,12.64H3A9.643,9.643,0,0,1,20.055,6.474V3.741h1.483V9.674H15.606Z"
                    transform="translate(-0.635 -0.738)"
                    fill="#fff"
                  />
                  <rect
                    id="_Transparent_Rectangle_"
                    data-name="&lt;Transparent Rectangle&gt;"
                    width="24"
                    height="24"
                    transform="translate(0)"
                    fill="none"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </symbol>
      <symbol
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44"
      >
        <g
          id="cashback-mobile"
          data-name="Group 6033"
          transform="translate(-4690 -3939)"
        >
          <circle
            id="Ellipse_162"
            data-name="Ellipse 162"
            cx="22"
            cy="22"
            r="22"
            transform="translate(4690 3939)"
            fill="#e82d76"
          />
          <g
            id="Group_6032"
            data-name="Group 6032"
            transform="matrix(-0.766, -0.643, 0.643, -0.766, 4713.385, 3977.816)"
          >
            <path
              id="Combined-Shape"
              d="M5.289,21.432a11.5,11.5,0,0,0,14.488.006,11.45,11.45,0,0,0,1.934-2.173A11.787,11.787,0,0,0,21.059,4.5l-.2-.233-.025,0-.079-.09A12.42,12.42,0,0,0,15.032.6,5.7,5.7,0,0,0,14.12.3,11.609,11.609,0,0,0,5.805,1.49q-.082.049-.162.1l-.006-.009A11.06,11.06,0,0,0,3.963,2.738,11.192,11.192,0,0,0,1.4,16.712a1.031,1.031,0,0,0,1.557.366L9.033,12a1,1,0,0,0,.1-1.414l-1.5-1.778,8.033.107-1.291,7.9L12.8,14.951a1.016,1.016,0,0,0-1.365-.2l-.059.044L5.213,19.914A.984.984,0,0,0,5.289,21.432ZM15.875,7.276l.009-.039L6.2,7.107a3.533,3.533,0,0,1-.819-1.749l-.017-.179A2.491,2.491,0,0,1,6.685,2.948a9.918,9.918,0,0,1,7.089-1.01l.107.028-.015.071a2.767,2.767,0,0,1,2.043,2,7.118,7.118,0,0,1,.07,2.66,5.041,5.041,0,0,1-.088.518Zm1.563,12.216a3.831,3.831,0,0,1-1.607-1.022l-.008-.012L17.617,7.485A9.108,9.108,0,0,0,17.534,3.6l-.008-.024.1.067a10.768,10.768,0,0,1,2.146,1.925l.2.234.01,0,.241.309a10.05,10.05,0,0,1,.046,12.226l-.206.254a4.552,4.552,0,0,1-.425.419A2.374,2.374,0,0,1,17.438,19.492ZM2.512,15.228l-.031-.067A9.6,9.6,0,0,1,3.6,5.584L3.7,5.448A5.072,5.072,0,0,0,5.278,8.59l.075.061L7.43,11.114Zm14.354,6.1A9.882,9.882,0,0,1,7.051,20.7l-.062-.043,4.993-4.15,2.482,2.941A5.548,5.548,0,0,0,17.013,21.2l.067.019Z"
              transform="translate(0 0)"
              fill="#fff"
              fillRule="evenodd"
            />
          </g>
        </g>
      </symbol>
      <symbol
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44"
      >
        <g
          id="certificate--check-mobile"
          data-name="Group 6028"
          transform="translate(-4693 -3532)"
        >
          <g
            id="Group_6027"
            data-name="Group 6027"
            transform="translate(-90 140)"
          >
            <g
              id="Group_6025"
              data-name="Group 6025"
              transform="translate(189 235)"
            >
              <circle
                id="Ellipse_160"
                data-name="Ellipse 160"
                cx="22"
                cy="22"
                r="22"
                transform="translate(4594 3157)"
                fill="#0b8967"
              />
              <g id="certificate--check" transform="translate(4604 3167.215)">
                <rect
                  id="Rectangle_839"
                  data-name="Rectangle 839"
                  width="5"
                  height="1"
                  transform="translate(4 11.785)"
                  fill="#fff"
                />
                <rect
                  id="Rectangle_840"
                  data-name="Rectangle 840"
                  width="8"
                  height="1"
                  transform="translate(4 8.785)"
                  fill="#fff"
                />
                <rect
                  id="Rectangle_841"
                  data-name="Rectangle 841"
                  width="8"
                  height="1"
                  transform="translate(4 5.785)"
                  fill="#fff"
                />
                <path
                  id="Path_5932"
                  data-name="Path 5932"
                  d="M10.857,20.237H3.476V5.476H21.189v7.381h1.476V5.476A1.476,1.476,0,0,0,21.189,4H3.476A1.476,1.476,0,0,0,2,5.476V20.237a1.476,1.476,0,0,0,1.476,1.476h7.381Z"
                  transform="translate(-0.524 -1.156)"
                  fill="#fff"
                />
                <path
                  id="Path_5933"
                  data-name="Path 5933"
                  d="M20.952,23.864l-1.912-1.912L18,22.993l2.952,2.952,5.9-5.9L25.816,19Z"
                  transform="translate(-4.715 -5.086)"
                  fill="#fff"
                />
                <rect
                  id="_Transparent_Rectangle_"
                  data-name="&lt;Transparent Rectangle&gt;"
                  width="24"
                  height="24"
                  transform="translate(0 -0.215)"
                  fill="none"
                />
              </g>
            </g>
          </g>
        </g>
      </symbol>
      <symbol
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44"
      >
        <g
          id="cloud--auditing-mobile"
          data-name="Group 6025"
          transform="translate(-4783 -3392)"
        >
          <g
            id="Group_6025-2"
            data-name="Group 6025"
            transform="translate(189 235)"
          >
            <circle
              id="Ellipse_160"
              data-name="Ellipse 160"
              cx="22"
              cy="22"
              r="22"
              transform="translate(4594 3157)"
              fill="#f59d45"
            />
            <g id="cloud--auditing" transform="translate(4604 3167)">
              <path
                id="Path_5927"
                data-name="Path 5927"
                d="M21.272,12.895v4.447H3.482V5.482h8.895V4H3.482A1.482,1.482,0,0,0,2,5.482v11.86a1.482,1.482,0,0,0,1.482,1.482h5.93v2.965H6.447v1.482h11.86V21.789H15.342V18.825h5.93a1.482,1.482,0,0,0,1.482-1.482V12.895ZM13.86,21.789H10.895V18.825H13.86Z"
                transform="translate(-0.518 -1.035)"
                fill="#fff"
              />
              <path
                id="Path_5928"
                data-name="Path 5928"
                d="M19.706,12.283,16,8.606l1.179-1.164,2.528,2.505L25.2,4.5l1.179,1.171Z"
                transform="translate(-4.14 -1.164)"
                fill="#fff"
              />
              <rect
                id="_Transparent_Rectangle_"
                data-name="&lt;Transparent Rectangle&gt;"
                width="24"
                height="24"
                fill="none"
              />
            </g>
          </g>
        </g>
      </symbol>
      <symbol
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44"
      >
        <g
          id="money-mobile"
          data-name="Group 6030"
          transform="translate(-4627 -3835)"
        >
          <circle
            id="Ellipse_161"
            data-name="Ellipse 161"
            cx="22"
            cy="22"
            r="22"
            transform="translate(4627 3835)"
            fill="#2babd9"
          />
          <g id="money" transform="translate(4637 3845)">
            <rect
              id="Rectangle_837"
              data-name="Rectangle 837"
              width="20"
              height="1"
              transform="translate(2 16)"
              fill="#fff"
            />
            <rect
              id="Rectangle_838"
              data-name="Rectangle 838"
              width="20"
              height="1"
              transform="translate(2 19)"
              fill="#fff"
            />
            <path
              id="Path_5920"
              data-name="Path 5920"
              d="M23.475,10a1.475,1.475,0,1,0,1.475,1.475A1.475,1.475,0,0,0,23.475,10Z"
              transform="translate(-5.775 -2.625)"
              fill="#fff"
            />
            <path
              id="Path_5921"
              data-name="Path 5921"
              d="M14.95,13.9a2.95,2.95,0,1,1,2.95-2.95,2.95,2.95,0,0,1-2.95,2.95Zm0-4.425a1.475,1.475,0,1,0,1.475,1.475A1.475,1.475,0,0,0,14.95,9.475Z"
              transform="translate(-3.15 -2.1)"
              fill="#fff"
            />
            <path
              id="Path_5922"
              data-name="Path 5922"
              d="M7.475,10A1.475,1.475,0,1,0,8.95,11.475,1.475,1.475,0,0,0,7.475,10Z"
              transform="translate(-1.575 -2.625)"
              fill="#fff"
            />
            <path
              id="Path_5923"
              data-name="Path 5923"
              d="M21.175,15.8H3.475A1.479,1.479,0,0,1,2,14.325V5.475A1.479,1.479,0,0,1,3.475,4h17.7A1.479,1.479,0,0,1,22.65,5.475v8.85A1.477,1.477,0,0,1,21.175,15.8Zm0-10.325H3.475v8.85h17.7Z"
              transform="translate(-0.525 -1.05)"
              fill="#fff"
            />
            <rect
              id="_Transparent_Rectangle_"
              data-name="&lt;Transparent Rectangle&gt;"
              width="24"
              height="24"
              fill="none"
            />
          </g>
        </g>
      </symbol>
      <symbol
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44"
      >
        <g
          id="group-mobile"
          data-name="Group 6021"
          transform="translate(-138 -1189)"
        >
          <g
            id="Group_6020"
            data-name="Group 6020"
            transform="translate(-4523 -1866)"
          >
            <g
              id="Group_6019"
              data-name="Group 6019"
              transform="translate(19834 5749)"
            >
              <g id="Group_5985" data-name="Group 5985">
                <circle
                  id="Ellipse_159"
                  data-name="Ellipse 159"
                  cx="22"
                  cy="22"
                  r="22"
                  transform="translate(-15173 -2694)"
                  fill="#7f2e9a"
                />
                <g id="group" transform="translate(-15163 -2684)">
                  <path
                    id="Path_6038"
                    data-name="Path 6038"
                    d="M27.254,27.86H25.79v-2.2a2.2,2.2,0,0,0-2.2-2.2h-2.93a2.2,2.2,0,0,0-2.2,2.2v2.2H17v-2.2A3.662,3.662,0,0,1,20.662,22h2.93a3.662,3.662,0,0,1,3.662,3.662Z"
                    transform="translate(-4.548 -5.886)"
                    fill="#fff"
                  />
                  <path
                    id="Path_6039"
                    data-name="Path 6039"
                    d="M22.662,11.465a2.2,2.2,0,1,1-2.2,2.2,2.2,2.2,0,0,1,2.2-2.2m0-1.465a3.662,3.662,0,1,0,3.662,3.662A3.662,3.662,0,0,0,22.662,10Z"
                    transform="translate(-5.083 -2.675)"
                    fill="#fff"
                  />
                  <path
                    id="Path_6040"
                    data-name="Path 6040"
                    d="M11.254,19.86H9.79v-2.2a2.2,2.2,0,0,0-2.2-2.2H4.662a2.2,2.2,0,0,0-2.2,2.2v2.2H1v-2.2A3.662,3.662,0,0,1,4.662,14h2.93a3.662,3.662,0,0,1,3.662,3.662Z"
                    transform="translate(-0.268 -3.746)"
                    fill="#fff"
                  />
                  <path
                    id="Path_6041"
                    data-name="Path 6041"
                    d="M6.662,3.465a2.2,2.2,0,1,1-2.2,2.2,2.2,2.2,0,0,1,2.2-2.2M6.662,2a3.662,3.662,0,1,0,3.662,3.662A3.662,3.662,0,0,0,6.662,2Z"
                    transform="translate(-0.803 -0.535)"
                    fill="#fff"
                  />
                  <rect
                    id="_Transparent_Rectangle_"
                    data-name="&lt;Transparent Rectangle&gt;"
                    width="24"
                    height="24"
                    fill="none"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </symbol>
      <symbol
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44"
      >
        <g
          id="money-green-mobile"
          data-name="Group 6023"
          transform="translate(-4594 -3157)"
        >
          <circle
            id="Ellipse_160"
            data-name="Ellipse 160"
            cx="22"
            cy="22"
            r="22"
            transform="translate(4594 3157)"
            fill="#76ba19"
          />
          <g id="money" transform="translate(4604 3167)">
            <rect
              id="Rectangle_837"
              data-name="Rectangle 837"
              width="22"
              height="1"
              transform="translate(1 17)"
              fill="#fff"
            />
            <rect
              id="Rectangle_838"
              data-name="Rectangle 838"
              width="22"
              height="1"
              transform="translate(1 20)"
              fill="#fff"
            />
            <path
              id="Path_5920"
              data-name="Path 5920"
              d="M23.5,10a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,23.5,10Z"
              transform="translate(-5.46 -2.482)"
              fill="#fff"
            />
            <path
              id="Path_5921"
              data-name="Path 5921"
              d="M15.007,14.015a3.007,3.007,0,1,1,3.007-3.007,3.007,3.007,0,0,1-3.007,3.007Zm0-4.511a1.5,1.5,0,1,0,1.5,1.5,1.5,1.5,0,0,0-1.5-1.5Z"
              transform="translate(-2.978 -1.985)"
              fill="#fff"
            />
            <path
              id="Path_5922"
              data-name="Path 5922"
              d="M7.5,10a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,7.5,10Z"
              transform="translate(-1.489 -2.482)"
              fill="#fff"
            />
            <path
              id="Path_5923"
              data-name="Path 5923"
              d="M21.548,16.029H3.5a1.508,1.508,0,0,1-1.5-1.5V5.5A1.508,1.508,0,0,1,3.5,4H21.548a1.507,1.507,0,0,1,1.5,1.5v9.022a1.506,1.506,0,0,1-1.5,1.5Zm0-10.526H3.5v9.022H21.548Z"
              transform="translate(-0.496 -0.993)"
              fill="#fff"
            />
            <rect
              id="_Transparent_Rectangle_"
              data-name="&lt;Transparent Rectangle&gt;"
              width="24"
              height="24"
              fill="none"
            />
          </g>
        </g>
      </symbol>
      <symbol
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44"
      >
        <g
          id="partnership-mobile"
          data-name="Group 6025"
          transform="translate(-4783 -3392)"
        >
          <g
            id="Group_6025-2"
            data-name="Group 6025"
            transform="translate(189 235)"
          >
            <circle
              id="Ellipse_160"
              data-name="Ellipse 160"
              cx="22"
              cy="22"
              r="22"
              transform="translate(4594 3157)"
              fill="#0b8967"
            />
            <g id="partnership" transform="translate(4604 3167)">
              <path
                id="Path_6042"
                data-name="Path 6042"
                d="M7.021,7.043a3.021,3.021,0,1,1,3.021-3.021A3.021,3.021,0,0,1,7.021,7.043Zm0-4.532A1.511,1.511,0,1,0,8.532,4.021,1.511,1.511,0,0,0,7.021,2.511Z"
                transform="translate(-0.979 -0.245)"
                fill="#fff"
              />
              <path
                id="Path_6043"
                data-name="Path 6043"
                d="M23.021,7.043a3.021,3.021,0,1,1,3.021-3.021A3.021,3.021,0,0,1,23.021,7.043Zm0-4.532a1.511,1.511,0,1,0,1.511,1.511A1.511,1.511,0,0,0,23.021,2.511Z"
                transform="translate(-4.894 -0.245)"
                fill="#fff"
              />
              <path
                id="Path_6044"
                data-name="Path 6044"
                d="M20.128,25.106H17.106A1.511,1.511,0,0,1,15.6,23.6V18.308h1.511V23.6h3.021V16.8h1.511V12.266a.755.755,0,0,0-.755-.755H16.034l-3.459,6.043L9.115,11.511H4.266a.755.755,0,0,0-.755.755V16.8H5.021v6.8H8.043V18.308H9.553V23.6a1.511,1.511,0,0,1-1.511,1.511H5.021A1.511,1.511,0,0,1,3.511,23.6V18.308A1.511,1.511,0,0,1,2,16.8V12.266A2.266,2.266,0,0,1,4.266,10H9.991l2.583,4.532L15.158,10h5.725a2.266,2.266,0,0,1,2.266,2.266V16.8a1.511,1.511,0,0,1-1.511,1.511V23.6A1.511,1.511,0,0,1,20.128,25.106Z"
                transform="translate(-0.489 -2.447)"
                fill="#fff"
              />
              <rect
                id="_Transparent_Rectangle_"
                data-name="&lt;Transparent Rectangle&gt;"
                width="24"
                height="24"
                fill="none"
              />
            </g>
          </g>
        </g>
      </symbol>
      <symbol
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44"
      >
        <g
          id="qr-code-mobile"
          data-name="Group 6025"
          transform="translate(-4783 -3392)"
        >
          <g
            id="Group_6025-2"
            data-name="Group 6025"
            transform="translate(189 235)"
          >
            <circle
              id="Ellipse_160"
              data-name="Ellipse 160"
              cx="22"
              cy="22"
              r="22"
              transform="translate(4594 3157)"
              fill="#1152c6"
            />
            <g id="qr-code" transform="translate(4604 3167)">
              <rect
                id="Rectangle_1502"
                data-name="Rectangle 1502"
                width="1.5"
                height="1.5"
                transform="translate(18 21) rotate(-90)"
                fill="#fff"
              />
              <rect
                id="Rectangle_1503"
                data-name="Rectangle 1503"
                width="1.5"
                height="1.5"
                transform="translate(13.5 18) rotate(-90)"
                fill="#fff"
              />
              <path
                id="Path_6045"
                data-name="Path 6045"
                d="M18,28.985h2.985V27.492H19.492V26H18Z"
                transform="translate(-4.569 -6.6)"
                fill="#fff"
              />
              <rect
                id="Rectangle_1504"
                data-name="Rectangle 1504"
                width="3"
                height="1.5"
                transform="translate(19.5 19.5) rotate(-90)"
                fill="#fff"
              />
              <path
                id="Path_6046"
                data-name="Path 6046"
                d="M27.492,26h1.492v2.985H26V27.492h1.492Z"
                transform="translate(-6.6 -6.6)"
                fill="#fff"
              />
              <path
                id="Path_6047"
                data-name="Path 6047"
                d="M26,19.492V18h2.985v2.985H27.492V19.492Z"
                transform="translate(-6.6 -4.569)"
                fill="#fff"
              />
              <path
                id="Path_6048"
                data-name="Path 6048"
                d="M22.985,20H21.492v2.985H20v1.492h2.985Z"
                transform="translate(-5.077 -5.077)"
                fill="#fff"
              />
              <rect
                id="Rectangle_1505"
                data-name="Rectangle 1505"
                width="1.5"
                height="3"
                transform="translate(13.5 15) rotate(-90)"
                fill="#fff"
              />
              <rect
                id="Rectangle_1506"
                data-name="Rectangle 1506"
                width="3"
                height="3"
                transform="translate(4.5 16.5)"
                fill="#fff"
              />
              <path
                id="Path_6049"
                data-name="Path 6049"
                d="M10.954,26.954H2V18h8.954ZM3.492,25.462H9.462V19.492H3.492Z"
                transform="translate(-0.508 -4.569)"
                fill="#fff"
              />
              <rect
                id="Rectangle_1507"
                data-name="Rectangle 1507"
                width="3"
                height="3"
                transform="translate(16.5 4.5)"
                fill="#fff"
              />
              <path
                id="Path_6050"
                data-name="Path 6050"
                d="M26.954,10.954H18V2h8.954ZM19.492,9.462h5.969V3.492H19.492Z"
                transform="translate(-4.569 -0.508)"
                fill="#fff"
              />
              <rect
                id="Rectangle_1508"
                data-name="Rectangle 1508"
                width="3"
                height="3"
                transform="translate(4.5 4.5)"
                fill="#fff"
              />
              <path
                id="Path_6051"
                data-name="Path 6051"
                d="M10.954,10.954H2V2h8.954ZM3.492,9.462H9.462V3.492H3.492Z"
                transform="translate(-0.508 -0.508)"
                fill="#fff"
              />
              <rect
                id="_Transparent_Rectangle_"
                data-name="&lt;Transparent Rectangle&gt;"
                width="24"
                height="24"
                fill="none"
              />
            </g>
          </g>
        </g>
      </symbol>
      <symbol
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44"
      >
        <g
          id="devices-mobile"
          data-name="Group 6046"
          transform="translate(-4848 -2857)"
        >
          <circle
            id="Ellipse_166"
            data-name="Ellipse 166"
            cx="22"
            cy="22"
            r="22"
            transform="translate(4848 2857)"
            fill="#008967"
          />
          <g id="devices" transform="translate(4858 2867)">
            <path
              id="Path_5899"
              data-name="Path 5899"
              d="M7.924,25.848H3.481A1.481,1.481,0,0,1,2,24.367V15.481A1.481,1.481,0,0,1,3.481,14H7.924A1.481,1.481,0,0,1,9.4,15.481v8.886A1.481,1.481,0,0,1,7.924,25.848ZM3.481,15.481v8.886H7.924V15.481Z"
              transform="translate(-0.519 -3.633)"
              fill="#fff"
            />
            <path
              id="Path_5900"
              data-name="Path 5900"
              d="M21.771,4H5.481A1.481,1.481,0,0,0,4,5.481V9.924H5.481V5.481h16.29V15.848H11.4v1.481h1.481V20.29H11.4v1.481h6.664V20.29h-3.7V17.328h7.4a1.481,1.481,0,0,0,1.481-1.481V5.481A1.481,1.481,0,0,0,21.771,4Z"
              transform="translate(-1.038 -1.038)"
              fill="#fff"
            />
            <rect
              id="_Transparent_Rectangle_"
              data-name="&lt;Transparent Rectangle&gt;"
              width="24"
              height="24"
              fill="none"
            />
          </g>
        </g>
      </symbol>
      <symbol
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44"
      >
        <g
          id="finance-mobile"
          data-name="Group 6044"
          transform="translate(-4776 -2707)"
        >
          <circle
            id="Ellipse_165"
            data-name="Ellipse 165"
            cx="22"
            cy="22"
            r="22"
            transform="translate(4776 2707)"
            fill="#f59d45"
          />
          <g id="finance" transform="translate(4786 2717)">
            <rect
              id="Rectangle_822"
              data-name="Rectangle 822"
              width="21"
              height="1"
              transform="translate(2 22)"
              fill="#fff"
            />
            <path
              id="Path_5901"
              data-name="Path 5901"
              d="M21.71,8.93a.77.77,0,0,0,.77-.77V5.85a.77.77,0,0,0-.508-.724L13.5,2.046a.77.77,0,0,0-.524,0l-8.47,3.08A.77.77,0,0,0,4,5.85V8.16a.77.77,0,0,0,.77.77h.77V18.94H4v1.54H22.48V18.94H20.94V8.93ZM5.54,6.389l7.7-2.8,7.7,2.8v1H5.54ZM14.78,18.94H11.7V8.93h3.08ZM7.08,8.93h3.08V18.94H7.08ZM19.4,18.94H16.32V8.93H19.4Z"
              transform="translate(-0.92 -0.46)"
              fill="#fff"
            />
            <rect
              id="_Transparent_Rectangle_"
              data-name="&lt;Transparent Rectangle&gt;"
              width="24"
              height="24"
              fill="none"
            />
          </g>
        </g>
      </symbol>
      <symbol
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44"
      >
        <g
          id="pay-mobile"
          data-name="Group 6048"
          transform="translate(-4915 -2903)"
        >
          <circle
            id="Ellipse_163"
            data-name="Ellipse 163"
            cx="22"
            cy="22"
            r="22"
            transform="translate(4915 2903)"
            fill="#e3192a"
          />
          <g id="Symbols" transform="translate(4923 2918)">
            <g id="Combined-Shape-2">
              <path
                id="Combined-Shape"
                d="M22.062,3.288l1.652,4.768L24.99,3.288H28l-2.8,8.089c-.867,2.5-2.421,3.535-4.94,3.42V12.232c1.161,0,1.668-.247,2.028-.986L19.02,3.288Zm-9.148-.23a2.857,2.857,0,0,1,2.323.986V3.288h2.715v8.451H15.237v-.986a2.857,2.857,0,0,1-2.323.986c-2.192,0-3.844-1.874-3.844-4.34S10.723,3.058,12.915,3.058ZM2.763,5.225v.447a.437.437,0,0,0,.077.248.432.432,0,0,0,.6.109L5.935,4.29a.436.436,0,0,0,0-.715L3.444,1.836a.433.433,0,0,0-.681.358v.419H0V0H4.449A3.952,3.952,0,0,1,8.538,4.028,3.952,3.952,0,0,1,4.449,8.056H2.928V11.33H0v-6.1Zm10.757.381A1.671,1.671,0,0,0,11.8,7.4a1.719,1.719,0,1,0,3.435,0A1.671,1.671,0,0,0,13.52,5.606Z"
                fill="#fff"
                fillRule="evenodd"
              />
            </g>
          </g>
        </g>
      </symbol>
      <symbol
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44"
      >
        <g
          id="quick-pay-mobile"
          data-name="Group 6042"
          transform="translate(-4677 -2506)"
        >
          <circle
            id="Ellipse_164"
            data-name="Ellipse 164"
            cx="22"
            cy="22"
            r="22"
            transform="translate(4677 2506)"
            fill="#1152c6"
          />
          <g
            id="Group_6041"
            data-name="Group 6041"
            transform="translate(4687.5 2516)"
          >
            <rect
              id="Rectangle_828"
              data-name="Rectangle 828"
              width="24"
              height="24"
              transform="translate(-0.5)"
              fill="none"
            />
            <g
              id="Group_3237"
              data-name="Group 3237"
              transform="translate(3.634 2.538)"
            >
              <g id="Group_3239" data-name="Group 3239">
                <path
                  id="Path_5909"
                  data-name="Path 5909"
                  d="M17.4,4.506H6.335C5.657,4.507,5,4.315,5,5.017V18.568a.625.625,0,0,0,.615.635H6.23a.607.607,0,0,0,.492-.254l1.352-1.864,1.352,1.864a.623.623,0,0,0,.984,0l1.352-1.864,1.352,1.864a.623.623,0,0,0,.984,0l1.352-1.864L16.8,18.949a.608.608,0,0,0,.492.254h.615a.625.625,0,0,0,.615-.635l.181-13.551c0-.7-.626-.51-1.3-.511Zm-.106,13-1.352-1.864a.623.623,0,0,0-.984,0l-1.352,1.864-1.352-1.864a.623.623,0,0,0-.984,0L9.918,17.509,8.566,15.645a.623.623,0,0,0-.984,0L6.23,17.509,6.335,5.777H17.4Z"
                  transform="translate(-3.477 0.594)"
                  fill="#fff"
                />
                <g
                  id="Rectangle_827"
                  data-name="Rectangle 827"
                  fill="none"
                  stroke="#fff"
                  strokeWidth="1.5"
                >
                  <rect width="16.751" height="6.193" stroke="none" />
                  <rect
                    x="0.75"
                    y="0.75"
                    width="15.251"
                    height="4.693"
                    fill="none"
                  />
                </g>
                <rect
                  id="Rectangle_788"
                  data-name="Rectangle 788"
                  width="2"
                  height="1"
                  transform="translate(10.865 13.462)"
                  fill="#fff"
                />
                <rect
                  id="Rectangle_789"
                  data-name="Rectangle 789"
                  width="6"
                  height="1"
                  transform="translate(3.865 13.462)"
                  fill="#fff"
                />
                <rect
                  id="Rectangle_790"
                  data-name="Rectangle 790"
                  width="2"
                  height="1"
                  transform="translate(10.865 10.962)"
                  fill="#fff"
                />
                <rect
                  id="Rectangle_791"
                  data-name="Rectangle 791"
                  width="6"
                  height="1"
                  transform="translate(3.865 10.962)"
                  fill="#fff"
                />
                <rect
                  id="Rectangle_792"
                  data-name="Rectangle 792"
                  width="9"
                  height="1"
                  transform="translate(3.865 8.462)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </g>
      </symbol>
      <symbol
        id="warning--filled"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <rect id="Transparent_Rectangle" width="24" height="24" fill="none" />
        <path
          id="Compound_Path"
          d="M12,2A10,10,0,1,0,22,12,10.029,10.029,0,0,0,12,2Zm-.786,4.286h1.571v7.857H11.214ZM12,18.429a1.071,1.071,0,1,1,1.071-1.071A1.1,1.1,0,0,1,12,18.429Z"
          fill="#ec6834"
        />
        <path
          id="inner-path"
          d="M17.5,23.5A1.5,1.5,0,1,1,16,22,1.538,1.538,0,0,1,17.5,23.5ZM17.1,8H14.9V19h2.2Z"
          transform="translate(-4 -4.267)"
          fill="rgba(0,0,0,0)"
          opacity="0"
        />
      </symbol>
    </svg>
  );
};

export default SvgIcons;
