import { createBrowserHistory } from "history";
export const history = createBrowserHistory();
// export const history = createBrowserHistory({forceRefresh:true})
history.listen((location, action) => {
  if (action === "POP") {
    history.replace(location.pathname, {});
  }
});

export const PATHNAMES_KEYS = {
  "/faq": "FAQ",
  "/pos": "POS",
  "/e-commerce": "ECOMERCE",
  "/cashback": "CASHBACK",
  "/billing": "BILLING",
  "/application/create": "ApplicationCreate",
  "/application/create/pos": "PosCreation",
  "/application/create/ecomerce": "ECommerceCreation",
  "/application/create/cashback": "CashBackCreation",
  "/application/create/billing": "BillingCreation",
};

export const PRODUCTS_URL = {
  POS: "/pos",
  BILLING: "/billing",
  CASHBACK: "/cashback",
  ECOMERCE: "/e-commerce",
};

export const PRODUCTS_IMG_URLS = [
  {
    img: "/images/pos1.png",
    key: "POS",
  },
  {
    img: "/images/ecommerce1.png",
    key: "ECOMERCE",
  },
  {
    img: "/images/cashback1.png",
    key: "CASHBACK",
  },
  {
    img: "/images/billing1.png",
    key: "BILLING",
  },
];
